.collapse-item-arrow {
  display: block;
  transition: transform 0.2s linear;

  .collapsed & {
    transform: scaleY(-1);
  }
}

.collapse-item-with-circle {
  border: $collapse-item-with-circle-border-width solid $collapse-item-with-circle-border-color;
  border-radius: 50%;
  width: $collapse-item-with-circle-width;
  height: $collapse-item-with-circle-height;
  position: relative;
  transition: transform 0.2s linear;
  transform: rotate(0deg);

  .collapse-item-arrow {
    font-weight: $font-weight-bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .collapsed & {
    transform: rotate(-180deg);
  }
}

.collapse-item {
  &:not(:last-child) {
    border-bottom: $collapse-item-border-width solid $collapse-item-border-color;
  }

  &.bordered {
    border-bottom: $collapse-item-border-width solid $collapse-item-border-color;
  }
}

.collapse-item-heading {
  cursor: pointer;
  padding: $collapse-item-padding-y-small 0 $collapse-item-padding-y-small;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  width: 100%;
  text-align: start;

  &:not(.collapse-item-small) {
    @include media-breakpoint-up(lg) {
      padding-top: $collapse-item-padding-y;
      padding-bottom: $collapse-item-padding-y;
    }
  }

  &:focus {
    border: 0;
  }

  &:focus-visible {
    outline: $outline-color auto 1px;
  }
}

.collapse-item-body {
  padding-bottom: $collapse-item-padding-y-small;

  @include media-breakpoint-up(lg) {
    padding-bottom: $collapse-item-padding-y;
  }
}

.collapse-arrow {
  transition: transform 0.2s linear;
}

.visible-when-collapsed {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.collapsed {
  .collapse-arrow {
    transform: scaleY(-1);
  }

  .visible-when-collapsed {
    opacity: 1;
  }
}
