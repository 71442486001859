.how-product-works {
  position: relative;
  background-color: $how-product-works-bg;
  overflow: hidden;
}

.how-product-works-features {
  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.how-product-works-inner-wrapper {
  // fixes scrollmagic resize bug
  width: 100% !important;
  height: 100vh;
}

.how-product-works-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-height: $how-product-works-container-height;
  padding-top: $navbar-promo-height + 2rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform: translateY(-50%);
  }

  &.how-product-works-container-with-features {
    @include media-breakpoint-up(lg) {
      padding-bottom: 15vh;
    }
  }
}

.how-product-works-scene {
  &.active {
    @extend %pointer-active;
  }
}

.how-product-works-scene-image-container {
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: $pointer-height + 1.25rem;

  @include media-breakpoint-up(sm) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-bottom: 0;
    transform: translate3d(0, 0, 0);
    transition-property: margin, transform;
    transition-duration: 1s;
    transition-timing-function: $how-product-works-transition-timing-function;

    .how-product-works.active & {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .how-product-works-scene.active & {
      transform: translate3d(0, -3rem, 0);
    }

    .how-product-works-scene:first-child & {
      margin-left: 0;
    }

    .how-product-works-scene:last-child & {
      margin-right: 0;
    }
  }

  > picture {
    display: block;
    height: 100%;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.how-product-works-scene-image {
  opacity: 1;
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: opacity 1s $how-product-works-transition-timing-function;

  .how-product-works.active .how-product-works-scene:not(.active) & {
    opacity: 0.2;
  }
}

.how-product-works-scene-text-container {
  position: absolute;
  right: 0;
  left: 0;

  @include media-breakpoint-down(md) {
    // overrides offset set in template
    top: $how-product-works-container-height !important;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -$pointer-height / 2;
    transform: translate3d(0, 0, 0);
    transition: transform 1s $how-product-works-transition-timing-function;

    .how-product-works-scene.active & {
      transform: translate3d(0, -3rem, 0);
    }
  }
}

.how-product-works-scene-text {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-delay: 0s, 0.5s;
  transition-timing-function: $how-product-works-transition-timing-function;
  transform: translate3d(0, 1.25rem, 0);
  color: $how-product-works-color;

  .how-product-works-scene.active & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }
}

.how-product-works-scene-text-title {
  @include media-breakpoint-up(lg) {
    display: inline-block;
    position: relative;

    &::before {
      // covers pointer line
      z-index: -1;
      position: absolute;
      top: 0;
      left: -1rem;
      right: -1rem;
      bottom: 0;
      background-color: $dark;
      content: '';

      .how-product-works-scene-text-left & {
        left: -100vw;
      }

      .how-product-works-scene-text-right & {
        right: -100vw;
      }
    }
  }
}

.how-product-works-scene-text-description {
  line-height: $line-height-base * 2em;
  transition: line-height 0.5s 0.5s $how-product-works-transition-timing-function;

  .how-product-works-scene.active & {
    line-height: $line-height-base * 1em;
    transition-delay: 0s;
  }
}

.how-product-works-progress {
  position: absolute;
  top: 50%;
  right: $grid-gutter-width;
  transform: translateY(-50%);
}
