.special-offer-banner {
  background-color: rgba($accent, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 0.5rem;
  max-width: 430px;
  padding: 1.25rem;
  border: 2px solid $accent;
  filter: drop-shadow(0 0 25px rgba($accent, 0.2));

  @include media-breakpoint-up(lg) {
    padding: 2.5rem;
  }
}
