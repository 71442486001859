.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.pointer-events-none {
  pointer-events: none !important;
}
