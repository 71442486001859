.product-box.card {
  border: none;

  .card-img {
    background-color: $gray-extra-light;
    border-radius: 0;
  }
}

.product-labels {
  z-index: 1;

  &.product-labels-right {
    right: 0;
  }

  .badge {
    margin-right: 0.5rem;
  }

  .text-right {
    .badge {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}

.product-image {
  margin-left: 10%;
  margin-right: 10%;

  .second-image {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .first-image:not(.second-image-disabled) {
        display: none;
      }

      & .first-image:last-child,
      & .second-image:last-child:not(.second-image-disabled) {
        display: block;
      }
    }
  }
}

.product-image-label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 1rem;
  margin-left: 1rem;
  background-position: top left;
  background-repeat: no-repeat;
  pointer-events: none;

  &.product-image-label-mobile-smaller {
    background-size: 2rem;

    @include media-breakpoint-up(lg) {
      background-size: auto;
    }
  }
}

.bundle-customize-box {
  .bundle-customize-image {
    @include media-breakpoint-up(lg) {
      height: 10rem;
      width: 10rem;
    }
  }

  .color-select {
    max-width: 15rem;
  }

  .product-image {
    margin: 0;
  }
}

.product-image-expand-button {
  position: absolute;
  right: 0;
  bottom: 0;

  &:focus-visible {
    outline: $outline-color auto 1px;
  }
}

.product-fullscreen-popup {
  z-index: $zindex-popover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  transform: translate3d(0, 100%, 0);
  transition: $fullscreen-popup-transition, opacity 0.15s $fullscreen-popup-transition-duration linear;

  &.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: $fullscreen-popup-transition;
  }

  .product-fullscreen-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .swiper-wrapper {
    height: 100vw;
    max-height: 80vh;
    width: 100vw;
  }

  .product-fullscreen-image {
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .swiper-slide {
    max-width: 80vh;
    overflow: hidden;
  }
}
