.cms-image {
  outline: $red solid 2px;
  outline-offset: -2px;
}

.admin-image {
  outline: $blue solid 2px;
  outline-offset: -2px;
}

.hardcoded-image {
  outline: $yellow solid 2px;
  outline-offset: -2px;
}
