.device-nav-item {
  opacity: 0.4;
  min-width: 5rem;
  transition: opacity 0.15s ease-in-out;

  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  .device-nav-item-text {
    @include media-breakpoint-up(lg) {
      white-space: nowrap;
    }
  }

  .device-nav-small-image {
    @include media-breakpoint-up(lg) {
      max-height: 2.75rem;
    }
  }
}
