.custom-dropdown {
  @include media-breakpoint-up(lg) {
    position: relative;
  }

  input[type='radio'] + label {
    border: $border-width $gray-light solid;
    border-radius: $border-radius;
    cursor: pointer;
  }

  input[type='radio']:checked + label {
    border: $border-width $extra-dark solid;
  }

  input[type='radio']:disabled + label {
    border: none;
    cursor: not-allowed;
    background-color: $gray-extra-light;
    color: $gray;
  }

  .custom-dropdown-button {
    padding: $custom-dropdown-padding-y ($custom-dropdown-padding-x + 1rem) $custom-dropdown-padding-y
      $custom-dropdown-padding-x;
    border-radius: $border-radius;
    position: relative;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      padding: $custom-dropdown-padding-lg-y $custom-dropdown-padding-lg-x;
    }

    &::after {
      position: absolute;
      right: 0;
      margin-right: 0.5rem;

      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }
    }

    &.custom-dropdown-button-border {
      border: $border-width $border-color solid;
    }

    &.dropdown-toggle::after {
      transform: scaleY(1) translate3d(0, -50%, 0);
      top: 50%;

      @include media-breakpoint-up(lg) {
        transform: scaleY(1);
        top: initial;
      }
    }

    &.active.dropdown-toggle::after {
      transform: scaleY(-1) translate3d(0, -50%, 0);
      top: 50%;

      @include media-breakpoint-up(lg) {
        transform: scaleY(-1);
        top: initial;
      }
    }
  }

  .custom-dropdown-menu {
    position: absolute;
    background-color: $white;
    top: 0;
    left: 0;
    box-shadow: 0 0 20px -10px $dark;
    border-color: transparent;
    z-index: $zindex-dropdown;
    border-radius: $border-radius;
    width: 100%;
    visibility: hidden;

    @include media-breakpoint-up(lg) {
      height: auto;
      position: absolute;
      min-height: auto;
    }

    &.active {
      visibility: visible;
    }

    .dropdown-toggle::after {
      transform: scaleY(1);
    }

    &.active .dropdown-toggle::after {
      transform: scaleY(-1);
    }
  }

  .custom-dropdown-menu-list {
    max-height: 66vh;
    overflow-y: auto;
  }

  .custom-dropdown-input:focus-visible + label,
  .custom-dropdown-input:focus + label {
    outline: $outline-color auto 1px;
  }
}

.custom-dropdown-column {
  position: relative;
}

.hidden-column {
  display: none !important;
}
