.block-text-with-full-image {
  .embed-responsive {
    @include media-breakpoint-up(lg) {
      max-height: 40.875rem;
    }

    &::before {
      padding-top: percentage(4 / 3);

      @include media-breakpoint-up(lg) {
        padding-top: percentage(9 / 16);
      }
    }
  }
}
