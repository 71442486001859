.stepper {
  height: 76px;
  max-width: 720px;
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    height: 184px;
  }

  .step-number {
    line-height: 2.5rem - px-to-rem(4px);
  }

  .stepper-item {
    &.disabled a {
      pointer-events: none;
      cursor: default;
    }

    .circle {
      display: inline-block;
      color: $gray-dark;
      border-radius: 50%;
      background: transparent;
      width: 2.5rem;
      height: 2.5rem;
      font-weight: 600;
      text-align: center;
      line-height: 2.7rem;
      position: relative;
      border: 2px solid $gray-dark;
    }

    &.active {
      .circle {
        background: $dark;
        color: $white;
        border: 2px solid $dark;
      }

      a {
        .label {
          color: $stepper-li-a-label-color;
        }
      }
    }

    &.completed {
      .circle {
        border: none;
      }
    }
  }

  .line {
    position: absolute;
    width: calc(100% - 150px);
    height: 2px;
    background: $gray-dark;
    top: 40px;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    @include media-breakpoint-up(lg) {
      top: 67px;
    }

    &.line-of-2-items {
      width: calc(100% - 300px);
    }
  }
}

.small-stepper {
  height: 76px;
  max-width: 720px;
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    height: 146px;
  }

  .small-stepper-item {
    &.disabled a {
      pointer-events: none;
      cursor: default;
    }

    .circle {
      display: inline-block;
      border-radius: 50%;
      background: $gray;
      width: 1rem;
      height: 1rem;
      z-index: 1;
    }

    &.active {
      .circle {
        background: $accent;
      }

      a {
        .label {
          color: $stepper-li-a-label-color;
        }
      }
    }

    &.completed {
      .circle {
        border: none;
      }
    }
  }

  .lines-wrapper {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: calc(100% - 100px);
    height: 2px;
    top: 30px;

    @include media-breakpoint-up(lg) {
      width: calc(100% - 160px);
      top: 58px;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background: $gray;
  }

  .label {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .line-cut {
    position: absolute;
    background-color: red;
    height: 20px;
    width: 200px;
    top: 0;
  }

  .line-cut-start {
    right: 50%;
  }

  .line-cut-end {
    left: 50%;
  }

  $steps: 1, 2, 3, 4;

  @each $step in $steps {
    @each $steps-count in $steps {
      .active-line-#{$step}-#{$steps-count} {
        position: absolute;
        width: calc(100% * ((#{$step} - 1) / (#{$steps-count} - 1)));
        height: 2px;
        background: $accent;

        @include media-breakpoint-up(lg) {
          width: calc(100% * ((#{$step} - 1) / (#{$steps-count} - 1)));
        }
      }
    }
  }
}
