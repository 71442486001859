.product-usps {
  position: relative;

  @include media-breakpoint-down(lg) {
    // overrides utility class
    background-color: transparent !important;
    color: inherit !important;
  }
}

.product-usps-inner-wrapper {
  // fixes scrollmagic resize bug
  width: 100% !important;
  height: 100vh;
}

.product-usps-scene {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.75s cubic-bezier(0, 0, 0, 1);

  &.active {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  }
}

.product-usps-container {
  z-index: 1;
  position: relative;

  @include media-breakpoint-up(lg) {
    top: 50%;
    transform: translateY(-50%);
  }
}

.product-usps-text {
  opacity: 0;
  position: relative;
  padding-top: $navbar-small-height;
  padding-bottom: 1rem;
  transform: translate3d(0, -0.5rem, 0);
  transition-property: opacity, transform;
  transition-duration: 0.75s;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 1);

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
    transform: translate3d(0, -50%, 0);
  }

  .product-usps-scene.active & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.product-usps-text-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: $product-usps-text-title-font-size-small;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: $product-usps-text-title-font-size;
    line-height: 1.08;
    letter-spacing: -1.28px;
  }
}

.product-usps-text-description {
  transform: translate3d(0, 1.5rem, 0);
  transition: transform 0.75s cubic-bezier(0.3, 0, 0, 1);

  .product-usps-scene.active & {
    transform: translate3d(0, 0, 0);
  }
}

.product-usps-video-wrapper {
  display: flex;
  align-items: stretch;
  flex-grow: 1;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.product-usps-video {
  width: 100%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

.product-usps-progress {
  position: absolute;
  bottom: 7rem;
  left: $grid-gutter-width;

  @include media-breakpoint-up(lg) {
    top: 50%;
    right: $grid-gutter-width;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
  }
}
