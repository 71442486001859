.cart-popup {
  z-index: $zindex-sticky;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  box-shadow: $cart-popup-box-shadow;
  transform: translate3d(0, 100%, 0);
  transition: $cart-popup-transition, opacity 0.15s $cart-popup-transition-duration linear;

  @include media-breakpoint-up($cart-popup-large-breakpoint) {
    top: 0;
    right: 0;
    left: auto;
    display: flex;
    flex-direction: column;
    width: $cart-popup-width;
    max-height: none;
    padding-top: $navbar-small-height;
    transform: translate3d(100%, 0, 0);

    @include media-breakpoint-up($navbar-breakpoint-up) {
      padding-top: $navbar-height;
    }

    .scroll-down & {
      padding-top: 0;
    }

    .navbar-promo-active & {
      top: $navbar-promo-height;
    }
  }

  &.cart-popup-visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: $cart-popup-transition;
  }
}

.cart-popup-body {
  padding: $cart-popup-body-padding-small-y $cart-popup-body-padding-small-x;

  @include media-breakpoint-up($cart-popup-large-breakpoint) {
    padding: $cart-popup-body-padding-y $cart-popup-body-padding-x;
  }
}

.cart-popup-footer {
  padding: 0 $cart-popup-footer-padding-small-x $cart-popup-footer-padding-small-bottom;

  @include media-breakpoint-up($cart-popup-large-breakpoint) {
    margin-top: auto;
    padding: 0 $cart-popup-footer-padding-x $cart-popup-footer-padding-bottom;
  }
}

.cart-popup-product-image {
  width: 5.75rem;
  height: auto;
}
