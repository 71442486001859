// Overrides google maps info window styles
.gm-style {
  .gm-style-iw {
    font-size: $font-size-body;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;

    &.gm-style-iw-c {
      box-shadow: none !important;
      max-height: none !important;
      max-width: none !important;
      border-radius: 0;
      background: none;
      pointer-events: none;

      // Compensate map content width to push info window into viewable area
      margin-left: ($map-content-width + $map-content-offset-left) / 2 !important;
      padding: 0 ($map-content-width + $map-content-offset-left) / 2 !important;

      > * {
        pointer-events: auto;
      }
    }

    .gm-style-iw-d {
      overflow: auto !important;
      max-height: calc(100vh - #{$navbar-height} - 160px) !important;
      max-width: none !important;
      box-shadow: $map-info-window-box-shadow;
      padding: $map-info-window-padding-y $map-info-window-padding-x;
      background-color: $map-info-window-bg;

      > div {
        width: $map-info-window-width - $map-info-window-padding-x * 2;
      }
    }

    > button {
      @extend .btn;
      @extend .btn-light;
      opacity: 1 !important;
      top: $map-info-window-padding-y !important;
      right: $map-info-window-padding-x + ($map-content-width + $map-content-offset-left) / 2 !important;
      width: $btn-height-small !important;
      height: $btn-height-small !important;
      padding: $btn-padding-y-small 0 !important;
      font-size: $btn-font-size-small !important;
      line-height: $btn-line-height-small !important;

      &::after {
        @extend %nbw-icon;
        vertical-align: bottom;
        font-size: inherit;
        line-height: inherit;
        content: $nbw-icon-close;
        transition: color 0.15s ease-out, transform 0.15s ease-in-out;
      }

      > img {
        display: none !important;
      }
    }
  }

  .gm-style-iw-tc {
    margin-left: ($map-content-width + $map-content-offset-left) / 2 !important;
  }

  .gm-style-iw-chr {
    margin-bottom: -$map-info-window-close-offset;
  }

  .gm-style-iw-t {
    &::after {
      content: none;
    }
  }
}
