.single-product-slider {
  max-height: 230px;

  img {
    max-height: 206px;
  }

  @include media-breakpoint-up(lg) {
    max-height: none;

    img {
      max-height: none;
    }
  }
}
