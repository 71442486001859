.image-select {
  min-height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  a {
    line-height: $image-select-height;

    &:hover {
      text-decoration: none;
    }
  }

  .image-select-item {
    position: relative;
    margin-right: $image-select-input-margin-x;
    width: $image-select-sm-width;
    height: $image-select-sm-height;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      margin-right: $image-select-input-lg-margin-x;
      width: $image-select-width;
      height: $image-select-height;
    }

    &.image-select-item-small {
      @include media-breakpoint-up(lg) {
        margin-right: $image-select-input-margin-x;
        width: $image-select-sm-width;
        height: $image-select-sm-height;

        > label::before {
          width: $image-select-sm-width;
          height: $image-select-sm-height;
        }

        img {
          height: $image-select-image-sm-height;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    > label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        border: $image-select-border-width solid transparent;
        border-radius: 4px;
        width: $image-select-sm-width;
        height: $image-select-sm-height;
        transform: translate3d(-50%, -50%, 0);
        transition: border 0.3s;
        content: '';
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        border-radius: 4px;
        width: $image-select-inner-width;
        height: $image-select-inner-height;
        background: inherit;
        transform: translate(-50%, -50%);
        content: '';
      }

      @include media-breakpoint-up(lg) {
        &::before {
          width: $image-select-width;
          height: $image-select-height;
        }
      }
    }

    > input {
      opacity: 0;

      &:focus-visible {
        outline-style: auto;
        outline-offset: 10px;
        outline-color: $outline-color;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        width: 0.3rem;
        height: 1.4rem;
        transform: translate(-50%, -50%);
      }

      @include media-breakpoint-up(lg) {
        &:focus-visible {
          outline-offset: 15px;
          width: 0.9rem;
          height: 2.1rem;
        }
      }

      &:checked ~ label {
        &::before {
          border-color: $image-select-border-color;
        }
      }

      &:not(:checked):hover ~ label {
        &::before {
          border-color: $image-select-border-color-hover;
        }
      }
    }

    img {
      height: $image-select-image-sm-height;
      width: auto;

      @include media-breakpoint-up(lg) {
        height: $image-select-image-height;
      }
    }
  }
}
