.styleguide-sidebar {
  transition: transform 0.3s;
  transform: translateX(0);

  &.toggled {
    transform: translateX(-100%);

    .btn-circular {
      right: -3rem;
    }

    .nbw-icon-arrow-left::before {
      display: block;
      transform: rotate(180deg);
    }
  }

  .btn-circular {
    position: absolute;
    top: 0.5rem;
    right: 0;
    transition: right 0.3s;
  }
}
