// Underlined link
.link-underlined {
  text-decoration: none;
  padding-bottom: $link-underline-height;
  background-color: transparent;
  background-image: linear-gradient(to right, $accent 50%, currentColor 50%);
  background-size: 200% $link-underline-height;
  background-position: 100% bottom;
  transition: background-position 0.5s ease-in-out;
  background-repeat: no-repeat;

  &.link-underlined-only-on-hover {
    background-image: linear-gradient(to right, $accent 50%, transparent 50%);
  }

  &.link-underlined-alt {
    background-image: linear-gradient(to right, $light 50%, currentColor 50%);

    &.link-underlined-only-on-hover {
      background-image: linear-gradient(to right, $light 50%, transparent 50%);
    }
  }

  &:hover {
    background-position: 0 bottom;
    text-decoration: none;
    transition-delay: 0s !important;
  }

  &.link-underlined-large {
    padding-bottom: $link-underline-large-height;
    background-size: 200% $link-underline-large-height;
  }

  &.loading {
    background-image: none;
  }
}

// Arrowed link
.link-arrowed-left,
.link-arrowed-right {
  display: inline-block;
  text-decoration: none;
  letter-spacing: $link-letter-spacing;

  &:hover {
    text-decoration: none;
  }
}

.link-arrowed-left:not(.loading)::before,
.link-arrowed-right:not(.loading)::after {
  @extend %nbw-icon;
  display: inline-block;
  margin-top: -0.25em;
  vertical-align: middle;
  font-size: inherit;
  transition: transform 0.2s ease;
}

.link-arrowed-left:not(.loading) {
  padding-left: $link-arrow-gutter + 1em;

  &:hover,
  &:focus {
    &::before {
      transform: translateX(-$link-arrow-gutter);
    }
  }

  &::before {
    margin-left: -$link-arrow-gutter - 1em;
    padding-right: $link-arrow-gutter;
    content: $nbw-icon-arrow-left-fat;
  }
}

.link-arrowed-right:not(.loading) {
  padding-right: $link-arrow-gutter + 1em;

  &:hover,
  &:focus {
    &::after {
      transform: translateX($link-arrow-gutter);
    }
  }

  &::after {
    margin-right: -$link-arrow-gutter - 1em;
    padding-left: $link-arrow-gutter;
    content: $nbw-icon-arrow-right-fat;
  }
}

// Resets button style
button.link,
button.link-underlined,
button.link-arrowed-left,
button.link-arrowed-right {
  border: none;
  padding: 0;
  color: inherit;
}

button.link,
button.link-arrowed-left,
button.link-arrowed-right {
  background: none;
}

.no-link {
  text-decoration: none !important;
  cursor: default !important;
}
