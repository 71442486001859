.bubbles-slider {
  .swiper-slide {
    overflow: hidden;
    transform: scale3d(0.9, 0.9, 1);
    border-bottom-right-radius: $bubbles-slider-slide-size-small / 2;
    border-bottom-left-radius: $bubbles-slider-slide-size-small / 2;
    width: $bubbles-slider-slide-size-small;
    padding-top: $bubbles-slider-slide-padding-top-small;
    transition: transform 0.9s $bubbles-slider-transition-timing-function;

    @include media-breakpoint-up(lg) {
      border-bottom-right-radius: $bubbles-slider-slide-size / 2;
      border-bottom-left-radius: $bubbles-slider-slide-size / 2;
      width: $bubbles-slider-slide-size;
      padding-top: $bubbles-slider-slide-padding-top;
    }
  }

  .swiper-slide-active {
    transform: scale3d(1, 1, 1);
  }
}

.bubbles-slider-slide-bg-container {
  position: relative;
}

.bubble-slider-slide-item {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 25%, 0);
  transition: transform 0.9s $bubbles-slider-transition-timing-function;

  .swiper-slide-active & {
    display: block;
    transform: translate3d(0, 0, 0);
  }
}

.bubble-slider-slide-active-bg,
.bubble-slider-slide-active-item-top {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bubble-slider-slide-active-bg {
  opacity: 0;
  transition: opacity 0.9s $bubbles-slider-transition-timing-function;

  .swiper-slide-active & {
    opacity: 1;
  }
}

.bubble-slider-slide-active-item-top {
  z-index: 1;
  opacity: 0;
  transform: translate3d(2%, 3%, 0) scale3d(0.9, 0.9, 1);
  transition: transform 0.9s $bubbles-slider-transition-timing-function,
    opacity 0.9s $bubbles-slider-transition-timing-function;

  .swiper-slide-active & {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
