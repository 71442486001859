.block-with-content-overflow-container {
  margin-top: -20vw;

  @include media-breakpoint-up(xl) {
    margin-top: -15rem;
  }

  &.block-with-content-overflow-container-smaller {
    @include media-breakpoint-up(lg) {
      margin-top: -10rem;
    }
  }
}

.block-with-content-overflow-container-mobile-2 {
  margin-top: -26vw;

  @include media-breakpoint-up(xl) {
    margin-top: -15rem;
  }

  &.block-with-content-overflow-container-smaller-mobile-2 {
    @include media-breakpoint-up(lg) {
      margin-top: -10rem;
    }
  }
}
