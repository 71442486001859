.gift-slider-arrows {
  position: relative;
  height: 60px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 50%;
    top: 29%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
    width: calc(100% + 44px);
  }

  .gift-slider-arrow {
    border: 1px solid $gray-extra-light;
    background-color: $gray-extra-light;

    @include media-breakpoint-up(lg) {
      background-color: $light;
      box-shadow: 4px 4px 35px -12px rgba(0, 0, 0, 1);
    }
  }
}
