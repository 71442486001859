%backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $navbar-backdrop-bg;
  opacity: 0;
  transition: opacity 0.15s linear;

  &.show {
    display: block;
  }

  &.enter-to {
    opacity: $navbar-backdrop-opacity;
  }
}
