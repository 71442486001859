// Hero slider animations

.fade-in-left {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-1.5rem, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes skewedSlideInFirstLayer {
  0% {
    transform: translate3d(-60%, 0, 0) skew(-21deg);
  }

  100% {
    transform: translate3d(110%, 0, 0) skew(-21deg);
  }
}

@keyframes skewedSlideInSecondLayer {
  0% {
    transform: translate3d(-50%, 0, 0) skew(-21deg);
  }

  100% {
    transform: translate3d(1000%, 0, 0) skew(-21deg);
  }
}

.slide-in-left {
  animation-name: slideInLeft;
  animation-duration: 1s;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-15%, 0, 0) scale3d(1.2, 1.2, 1);
  }

  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// --------

.hero-slider {
  min-height: calc(100vh - #{$navbar-small-height});

  @include media-breakpoint-up(lg) {
    height: calc(100vh - #{$navbar-small-height});
    min-height: 42.5rem;
    max-height: 46.875rem;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      height: calc(100vh - #{$navbar-height});
    }
  }

  &.end-of-year-hero-slider {
    min-height: 100vw;

    .hero-slider-navigation {
      top: 50%;
    }

    .hero-slider-image-container img {
      object-position: center -20vw;
    }

    @include media-breakpoint-up(lg) {
      min-height: 42.5rem;

      .hero-slider-image-container img {
        object-position: center;
      }
    }
  }

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video.slide-video {
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -20%;
      transform-origin: top left;
      z-index: 4;
      animation-name: skewedSlideInSecondLayer, fadeOut;
      animation-duration: 2.5s, 1.25s;
      animation-fill-mode: both, both;
      animation-delay: 600ms, 750ms;
      width: 500%;
      height: 100%;
      background: $accent;

      @include media-breakpoint-up(lg) {
        width: 40%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform-origin: top left;
      z-index: 3;
      animation-name: skewedSlideInFirstLayer;
      animation-duration: 2.5s;
      animation-fill-mode: both;
      animation-delay: 350ms;
      width: 500%;
      height: 100%;
      background: $light;

      @include media-breakpoint-up(lg) {
        width: 180%;
      }
    }
  }

  .swiper-slide {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: $accent;
      width: 130%;
      height: 120%;
      transform: translate3d(-120%, 0, 0) skew(-21deg);
      transform-origin: top left;
      z-index: 3;
    }

    &.swiper-slide-active {
      &::before {
        transition: transform 1.5s;
        transform: translate3d(190%, 0, 0) skew(-21deg);

        @include media-breakpoint-up(lg) {
          transform: translate3d(120%, 0, 0) skew(-21deg);
        }
      }

      .hero-slider-slide-in-content-first,
      .hero-slider-slide-in-content-second {
        @extend .fade-in-left;
        opacity: 1;
      }

      .hero-slider-slide-in-content-first {
        animation-delay: 0.8s;
      }

      .hero-slider-slide-in-content-second {
        animation-delay: 1.3s;
      }

      .video-btn {
        pointer-events: auto;
      }
    }

    &.video-slide video {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .video-btn {
      pointer-events: none;
    }

    img {
      @extend .slide-in-left;
      animation-delay: 0.3s;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .slider-overlay {
    position: relative;
    z-index: 2;

    img {
      max-width: 100%;
      height: auto !important;
    }

    p {
      margin-bottom: 0;
    }

    .video-button-slider-wrapper {
      position: relative;
      width: 100%;
      height: 100px;
      margin-top: 3%;
      margin-bottom: 9%;

      .video-btn-wrapper.video-btn-custom {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);

        @include media-breakpoint-up(lg) {
          left: 55px;
        }
      }
    }
  }
}

.hero-slider-slide-in-content-container {
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;

  @include media-breakpoint-up(lg) {
    top: 19%;
    width: 50%;
  }
}

.hero-slider-slide-in-content-first,
.hero-slider-slide-in-content-second {
  opacity: 0;
  transition: opacity 0.8s;
}

.hero-slider-navigation {
  z-index: 1;
  position: absolute;
  top: calc(105vw - #{$btn-height});
  right: 4.5%;
  left: 4.5%;

  @include media-breakpoint-up(lg) {
    top: 50%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
  }

  .swiper-button-light.swiper-button-disabled {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }
}

.hero-slider-trio-blocks {
  @extend .fade-in;
  animation-delay: 2s;
  z-index: 10;
  position: relative;
  margin-top: 105vw;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-slider-trio-blocks-container {
  background-color: rgba($dark, 0.3);
  border-radius: 0.25rem;
}

.hero-slider-trio-blocks-5-column {
  @include media-breakpoint-up(lg) {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

.hero-slider-trio-blocks-block-content-background {
  background-color: $light;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-slider-trio-blocks-block-content-container {
  width: 100%;
  height: 100%;
  min-height: 6.25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  transition: color 0.4s;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 5.25rem;
  background-origin: content-box;

  @include media-breakpoint-up(lg) {
    min-height: 8.75rem;
    background-size: 7.125rem;
  }

  & > * {
    z-index: 2;
  }
}

.hero-slider-trio-blocks-block-content-image {
  position: absolute;
  right: 0;
  transform: translate3d(0, -50%, 0);
  top: 50%;

  > svg {
    width: 5.25rem;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: 7.125rem;
    }

    .hover-stroke {
      transition: stroke 0.4s;
    }

    .hover-fill {
      transition: fill 0.4s;
    }
  }
}

.hero-slider-trio-blocks-block-background {
  position: absolute;
  top: 0;
  left: 0;
  background: $dark;
  width: 180%;
  height: 120%;
  transition: transform 0.4s ease-in-out;
  transform: translate3d(-125%, 0, 0) skew(-21deg);
  z-index: 1;
}

.hero-slider-trio-blocks-block {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 0.25rem;
  height: 100%;

  &:hover {
    text-decoration: none;

    .hero-slider-trio-blocks-block-content-container {
      color: $light;
    }

    .hero-slider-trio-blocks-block-content-image {
      .hover-stroke {
        stroke: $light;
      }

      .hover-fill {
        fill: $light;
      }
    }

    .hero-slider-trio-blocks-block-background {
      transform: translate3d(-25%, 0, 0) skew(-21deg);
    }
  }
}

.hero-slider-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-slider-slide-title {
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
