.qure-top-bar {
  min-height: 58px;

  @include media-breakpoint-up(lg) {
    height: 76px;
  }
}

.qure-top-bar-title {
  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size-large;
  }
}

.qure-section {
  ul li {
    color: $dark;
  }
}

.qure-content-block {
  padding: 2.7rem 3rem;

  @include media-breakpoint-up(lg) {
    padding: 2rem 3.7rem;
  }

  a {
    @extend .link-underlined;
  }
}

.solution-buttons-block {
  background-color: $gray-extra-dark;
  padding: 1.6rem 3rem;

  @include media-breakpoint-up(lg) {
    padding: 2rem 3.7rem;
  }

  p:last-child {
    margin: 0;
  }
}

.qure-solution-image {
  img {
    max-height: 350px;
    height: auto;
    margin-left: auto;
  }
}
