.modal-video {
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

  .modal-video-close-btn {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    right: 0;

    &::before,
    &::after {
      height: 1px;
      margin-top: 0;
    }

    @include media-breakpoint-down(lg) {
      top: calc(50% + 2rem - 50vh);
    }
  }
}

.modal-video-body {
  max-width: 1086px;
  padding: 0 $grid-gutter-width;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }
}
