.checkout-message {
  width: 100%;
  background-color: $checkout-message-bg;
  padding: $checkout-message-padding-y $checkout-message-padding-x;

  @include media-breakpoint-up($checkout-message-breakpoint-up) {
    border-radius: $checkout-message-border-radius;
    padding: $checkout-message-up-padding-y $checkout-message-up-padding-x;
  }

  &.collapsed {
    .checkout-message-close {
      @include media-breakpoint-down($checkout-message-breakpoint-down) {
        pointer-events: none;

        > .nbw-icon::before {
          content: $nbw-icon-chevron-up;
        }
      }
    }
  }
}

.checkout-message-floating {
  z-index: $zindex-fixed;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  // fallback for browsers that do not support CSS variables
  max-height: calc(100vh - #{$navbar-small-height});
  // fix for Mobile browsers to get real viewport height (without top and bottom bars)
  max-height: calc(var(--vh, 1vh) * 100 - #{$navbar-small-height});

  @include media-breakpoint-up($navbar-breakpoint-up) {
    max-height: calc(100vh - #{$navbar-height});
    max-height: calc(var(--vh, 1vh) * 100 - #{$navbar-height});
  }

  .navbar-promo-active & {
    max-height: calc(100vh - #{$navbar-small-computed-height});
    max-height: calc(var(--vh, 1vh) * 100 - #{$navbar-small-computed-height});

    @include media-breakpoint-up($navbar-breakpoint-up) {
      max-height: calc(100vh - #{$navbar-computed-height});
      max-height: calc(var(--vh, 1vh) * 100 - #{$navbar-computed-height});
    }
  }

  @include media-breakpoint-up($checkout-message-breakpoint-up) {
    left: auto;
    right: $checkout-message-floating-offset-right;
    bottom: $checkout-message-floating-offset-bottom;
    box-shadow: $checkout-message-floating-box-shadow;
    width: $checkout-message-floating-width;
  }
}

.checkout-message-product-list {
  border-radius: $checkout-message-product-list-border-radius;
  background-color: $checkout-message-product-list-bg;
}

.checkout-message-product-image {
  width: $checkout-message-product-image-widht;
  min-width: $checkout-message-product-image-widht;
}

// Animations

.checkout-message {
  &.leave-to {
    opacity: 0;
    transform: translate3d(0, 5rem, 0);
    transition: transform 0.2s ease-in, opacity 0.2s linear;

    @include media-breakpoint-up($checkout-message-breakpoint-up) {
      transform: scale3d(0.9, 0.9, 1);
      transform-origin: top right;
    }
  }
}

.checkout-message-floating {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s ease-out, opacity 0.3s linear;

  &.enter-to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include media-breakpoint-up($checkout-message-breakpoint-up) {
    transform: scale3d(1, 1, 1) translate3d(0, 3rem, 0);
  }
}
