.banner-slider-container {
  margin: 0 -2rem;
  min-height: 13.125rem;

  @include media-breakpoint-up(sm) {
    min-height: 6.25rem;
  }

  @include media-breakpoint-up(lg) {
    margin: 0;
  }

  .swiper-wrapper {
    .timer-wrapper {
      opacity: 0;
      @include transition(opacity 0.2s ease);

      &.timer-loaded {
        opacity: 1;
      }
    }
  }
}
