.ratings-and-reviews {
  max-width: map-get($container-max-widths, xxl);
  margin: 0 auto;
}

.rating-summary {
  min-height: 17px;
}

.inline-rating {
  min-height: 1.5rem;
}

.bv-cv2-cleanslate {
  .bv-content-list-container {
    .bv-header {
      .bv-action-bar {
        .bv-action-bar-header {
          font-size: $h3-font-size !important;

          @include media-breakpoint-up(lg) {
            font-size: $h2-font-size-large !important;
          }
        }
      }
    }
  }

  .bv-shared {
    .bv-header {
      .bv-section-summary {
        margin-bottom: 2.5rem !important;

        .bv-section-summary-inline {
          .bv-inline-histogram-ratings {
            margin-bottom: 2.375rem !important;

            @include media-breakpoint-up(lg) {
              margin-bottom: 3.125rem !important;
            }
          }
        }
      }
    }

    .bv-compat {
      .bv-content-search {
        margin-bottom: 4.375rem !important;
        background: transparent !important;
        box-shadow: none !important;
        border-bottom: 0 !important;

        .bv-masthead {
          background: transparent !important;

          .bv-masthead-product {
            padding-left: 0 !important;

            .bv-stars-container {
              background: transparent !important;
            }

            .bv-stars-container {
              .bv-rating-stars-on {
                color: $gold !important;
              }
            }

            .bv-secondary-summary {
              .bv-percent-recommend-container {
                font-size: $font-size-extra-small !important;
                line-height: $line-height-base !important;
                color: $dark !important;
                font-weight: normal !important;
                padding: 0 !important;
                margin: 0 !important;
              }
            }
          }
        }

        .bv-masthead-search {
          .bv-fieldsets {
            padding-left: 0 !important;

            input.bv-text {
              padding: 0.5rem 1.125rem !important;
              border: 1px solid $gray-light !important;
              border-right: 0 !important;
              box-shadow: none !important;
              font-size: 0.8rem !important;
              color: $dark !important;
            }
          }

          .bv-content-search-btn {
            float: none !important;
            position: relative !important;
            width: auto !important;
            height: 43px !important;
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
            border: 1px solid $gray-light !important;
            border-left: 0 !important;
            background: $white !important;
            padding: 0 1.25rem !important;
            font-weight: 100 !important;
          }
        }

        .bv-generic-submission {
          .bv-fieldsets {
            padding-left: 0 !important;
          }
        }
      }
    }

    .bv-inline-histogram-ratings-bar {
      .bv-content-secondary-ratings-container {
        background-color: $gray-light !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        background-image: none !important;
        box-shadow: none !important;

        .bv-content-secondary-ratings-value {
          background-color: $dark !important;
          border-radius: 0 !important;
          -webkit-border-radius: 0 !important;
          background-image: none !important;
          box-shadow: none !important;
        }
      }
    }

    .bv-content-secondary-ratings-container {
      .bv-content-secondary-ratings-value {
        background-color: $dark !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        background-image: none !important;
        box-shadow: none !important;
      }
    }

    .bv-secondary-rating-summary-bars {
      .bv-secondary-rating-summary-value,
      .bv-secondary-slider-summary-value,
      .bv-content-slider-value {
        background-color: $dark !important;
        background-image: none !important;
        box-shadow: none !important;
      }
    }

    .bv-content-list-container {
      .bv-header {
        padding: 1.875rem 2rem 1.875rem !important;

        @include media-breakpoint-up(lg) {
          padding: 3.125rem 2rem 1.875rem !important;
        }
      }
    }

    .bv-inline-histogram-ratings {
      .bv-flex-container-column {
        margin: 0 !important;
        margin-top: 1.75rem !important;
        width: 100% !important;

        @include media-breakpoint-up(lg) {
          width: 85% !important;
        }
      }
    }

    .bv-glyph {
      color: $gold !important;
    }

    .bv-rating-stars-container {
      .bv-rating-stars {
        letter-spacing: 0.375rem !important;
      }
    }

    .bv-content-list {
      .bv-content-item {
        padding: 0 2rem !important;
        margin-bottom: 1rem !important;

        @media (min-width: 715px) {
          padding: 2.5rem !important;
          margin: 0 2rem 1rem !important;
        }
      }
    }

    .bv-content-item.bv-content-review {
      .bv-author-profile {
        padding: 0 !important;

        .bv-inline-profile {
          .bv-author-avatar {
            margin-bottom: 2.875rem !important;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .bv-content-item-author-profile-offset-on {
        .bv-content-header {
          .bv-content-rating {
            margin-bottom: 1rem !important;
          }

          .bv-content-meta-wrapper {
            margin-bottom: 1.25rem !important;
          }
        }
      }
    }

    .bv-content-core {
      .bv-content-summary {
        .bv-content-summary-body-text {
          margin-bottom: 2rem !important;
        }
      }
    }

    .bv-content-data-label-container {
      .bv-content-data-icon {
        width: 1.5rem !important;
        height: 1.5rem !important;
        position: relative !important;
        flex-shrink: 0 !important;
        margin-right: 0.8rem !important;
        background-color: $accent !important;
        transform: rotate(45deg) !important;
        border-radius: 50% !important;
        display: inline-block !important;
        vertical-align: middle !important;
        text-indent: -9999px !important;
        line-height: 0 !important;
      }

      .bv-content-data-icon:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        bottom: auto;
        left: auto;
        width: 0.3rem;
        height: 0.1rem;
        background: $dark;
        transform: translate(1px, 2px);
      }

      .bv-content-data-icon:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        bottom: auto;
        left: auto;
        width: 0.1rem;
        height: 0.6rem;
        background: $dark;
        transform: translate(2px, -6px);
      }
    }

    .bv-content-title {
      font-weight: $font-weight-bold !important;
      margin-bottom: 0.625rem !important;
    }
  }

  .bv-section-summary-inline {
    .bv-secondary-rating-summary {
      .bv-secondary-rating-summary-bars-container {
        .bv-secondary-rating-summary-container {
          .bv-secondary-rating-summary-bars {
            background-color: $gray-light !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            background-image: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .bv-content-item {
    .bv-secondary-ratings {
      .bv-content-secondary-ratings {
        .bv-popup-histogram-ratings-bar {
          .bv-content-secondary-ratings-container {
            background-color: $gray-light !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            background-image: none !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .bv-content-item-author-profile-offset-on {
      margin-top: 0 !important;
    }
  }

  .bv-content-filter.bv-filter-controls-visible {
    .bv-filter-controls {
      .bv-filters {
        padding: 15px 2rem !important;
      }
    }

    .bv-active-filters {
      padding: 10px 2rem !important;
    }
  }

  .bv-section-summary {
    .bv-secondary-rating-summary-header {
      .bv-content-title {
        margin-bottom: 0.75rem !important;

        @include media-breakpoint-up(lg) {
          margin-bottom: 2.375rem !important;
        }
      }
    }
  }

  .bv-mbox-inner {
    .bv-mbox-wide {
      .bv-mbox {
        background-color: $dark !important;
      }
    }
  }

  .bv-profull-sidebar,
  .bv-mbox-fullprofile {
    .bv-author-profile-userinfo {
      .bv-author-userstats-list {
        .bv-author-userstats-data,
        .bv-author-userstats-value {
          color: $white !important;
        }
      }
    }
  }

  .bv-section-summary {
    .bv-section-summary-inline {
      .bv-section-summary-block {
        .bv-inline-histogram-ratings {
          .bv-histogram-filter-helper {
            margin-left: 0 !important;
            margin-bottom: 2.5rem !important;
            padding-left: 0 !important;
            padding-bottom: 0 !important;

            @include media-breakpoint-up(lg) {
              margin-bottom: 0.25rem !important;
            }
          }
        }
      }
    }
  }

  .bv-content-data-summary {
    .bv-content-header-meta {
      margin-bottom: 0.375rem !important;
    }
  }

  .bv-header {
    .bv-action-bar {
      padding-top: 0.125rem !important;
      padding-bottom: 1rem !important;

      @include media-breakpoint-up(lg) {
        padding-top: 1.25rem !important;
        padding-bottom: 1.75rem !important;
      }
    }

    .bv-control-bar {
      border-bottom: 1px solid $gray-light !important;
    }

    .bv-section-summary {
      .bv-inline-histogram-ratings-star-container.bv-flex-container {
        margin-bottom: 0.75rem !important;

        @include media-breakpoint-up(lg) {
          margin-bottom: 3px !important;
        }
      }
    }
  }

  .bv-content-list-container {
    .bv-content-placeholder {
      padding: 3.125rem 2rem 1.875rem !important;

      .bv-rating-stars-container.bv-rating-none {
        margin-bottom: 0.375rem !important;
        margin-top: 0 !important;
      }
    }
  }

  .bv-content-pagination-container {
    .bv-content-pagination-buttons {
      .bv-content-pagination-buttons-item {
        .bv-content-btn {
          @extend .btn;
          height: 2.75rem !important;
          width: 2.75rem !important;
          border: 0 !important;
          border-radius: 50% !important;
          padding: 0 !important;
          background-color: $white !important;
          z-index: 1 !important;
          overflow: hidden !important;
          position: relative !important;
          transition: $btn-transition !important;

          &.bv-content-btn-pages-active {
            &::before {
              background-color: $accent;
            }

            &:hover,
            &:focus,
            &.focus &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
              outline: 0 !important;
              color: $dark !important;
              opacity: 1 !important;
              box-shadow: none !important;
            }
          }

          &.bv-content-btn-pages-first {
            margin-right: 1.25rem !important;
          }

          &.bv-content-btn-pages-inactive {
            background-color: rgba($white, 0.2) !important;

            .bv-content-btn-pages-prev::before,
            .bv-content-btn-pages-next::before {
              color: $gray-dark;
            }
          }
        }

        .bv-content-btn-pages-prev,
        .bv-content-btn-pages-next {
          @extend %nbw-icon;
          color: transparent !important;
          border: 0;
          background-color: $white;

          &:before {
            position: absolute;
            color: $dark;
            font-size: $font-size-extra-large;
            content: $nbw-icon-chevron-right;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }

        .bv-content-btn-pages-prev {
          &::before {
            content: $nbw-icon-chevron-left;
          }
        }

        .bv-content-btn-pages-next {
          &::before {
            content: $nbw-icon-chevron-right;
          }
        }
      }
    }
  }

  .bv-content-pagination {
    .bv-content-pagination-container {
      margin: 0 2rem 1rem !important;
    }
  }

  .bv-inline-histogram-ratings-explanation {
    margin-top: 0.75rem !important;
    cursor: pointer !important;

    .bv-inline-histogram-ratings-explanation-icon {
      box-sizing: border-box !important;
      display: inline-block !important;
      margin-right: 0.5rem !important;
      border-radius: 50% !important;
      min-width: $circle-icon-width-small !important;
      width: $circle-icon-width-small !important;
      height: $circle-icon-height-small !important;
      background-color: $accent !important;
      padding-top: ($circle-icon-height-small - $circle-icon-font-size-small) / 2 !important;
      padding-bottom: ($circle-icon-height-small - $circle-icon-font-size-small) / 2 !important;
      speak: never !important;
      font-family: '#{$nbw-icons-font-family}' !important;
      line-height: 1 !important;
      font-size: $circle-icon-font-size-small !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      text-align: center !important;
      color: $circle-icon-color !important;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;

      &::before {
        content: $nbw-icon-exclamation-mark !important;
      }
    }

    .bv-inline-histogram-ratings-explanation-label {
      font-weight: $font-weight-bold !important;
      font-size: $font-size-base !important;
      line-height: $line-height-base !important;
      color: $dark !important;
    }
  }
}

span {
  &.bv-author-userstats-data,
  &.bv-author-userstats-value {
    color: $gray-dark !important;
  }
}

.bv_main_container {
  .bv_stars_svg_no_wrap svg,
  .bv_stars_svg_no_wrap polygon {
    padding: 0 0.25rem !important;
  }
}

.ratings-and-reviews-btn {
  @extend .btn;
  font-weight: $font-weight-normal !important;
  font-size: $btn-font-size !important;
  line-height: $btn-line-height !important;
  border-radius: $btn-border-radius !important;
  z-index: 1 !important;
  overflow: hidden !important;
  position: relative !important;
  border: 0 !important;
  transition: $btn-transition !important;
  padding: $btn-padding-y $btn-padding-x !important;
  box-shadow: inset 0 0 0 2px $dark !important;

  &:hover,
  &:focus,
  &.focus &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    outline: 0 !important;
    color: $dark !important;
    opacity: 1 !important;
  }
}

.bv_modal_outer_content {
  .bv_histogram_row_bar_filled {
    background-color: $dark !important;
  }

  .bv_button_buttonFull,
  .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit {
    @extend .ratings-and-reviews-btn;
    @include button-variant(
      map-get(map-get($btn-colors, 'dark'), 'bg'),
      map-get(map-get($btn-colors, 'dark'), 'active-bg'),
      map-get(map-get($btn-colors, 'dark'), 'disabled-color'),
      map-get(map-get($btn-colors, 'dark'), 'disabled-bg')
    );
  }

  .bv_button_component_container {
    margin-top: 1.25rem;
  }
}

[data-bv-rating],
[data-bv-show='inline_rating'] {
  .bv_main_container {
    .bv_text {
      color: $gray-dark !important;
      font-size: $font-size-small !important;
      font-weight: $font-weight-normal !important;
      padding-top: 0 !important;
    }
  }
}

#ratings-summary.bv_main_container_row_flex {
  outline: none;
}
