.pills-tabs {
  margin-bottom: $tabs-space-between-content;

  .nav-item {
    padding-right: $tabs-padding-x;
  }

  .nav-link {
    color: $tabs-link-color;
    padding: 0;
  }

  .nav-link-text {
    padding-bottom: $tabs-link-text-padding-y;
    font-weight: $font-weight-bold;
  }

  .nav-link.active {
    .nav-link-text {
      border-bottom: $tabs-link-text-border;
    }
  }

  .tab-description {
    color: $tabs-link-description-color;
    font-size: $tabs-link-description-size;
    padding-top: $tabs-link-description-padding-y;
    opacity: 0;
    @include transition(opacity 0.3s ease);
  }

  .nav-link.active + .tab-description {
    opacity: 1;
  }
}
