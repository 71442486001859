.map-container {
  position: relative;
  height: calc(100vh - #{$navbar-small-height + $collapsible-footer-toggle-height});

  @include media-breakpoint-up($navbar-breakpoint-up) {
    height: calc(100vh - #{$navbar-height});
  }

  .navbar-promo-active & {
    height: calc(100vh - #{$navbar-small-computed-height + $collapsible-footer-toggle-height});

    @include media-breakpoint-up($navbar-breakpoint-up) {
      height: calc(100vh - #{$navbar-computed-height});
    }
  }
}

.map-top-left,
.map-bottom-left {
  z-index: 1;
  position: absolute;
  left: $map-controls-offset-small;
  transform: translate3d(0, 0, 0); // Fixes map content flickering issue on iOS

  @include media-breakpoint-up(md) {
    left: $map-content-offset-left + $map-content-width + $map-controls-offset;
  }
}

.map-top-left {
  top: $map-controls-offset-small;

  @include media-breakpoint-up(md) {
    top: $map-controls-offset;
  }
}

.map-bottom-left {
  bottom: $map-controls-offset-small;

  @include media-breakpoint-up(md) {
    bottom: $map-controls-offset;
  }
}

.map-content {
  z-index: 1;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $map-content-body-padding-top-small $map-content-body-padding-x-small 0;
  background: $map-content-bg;

  @include media-breakpoint-up(md) {
    top: $map-content-offset-top;
    bottom: auto;
    left: $map-content-offset-left;
    width: $map-content-width;
    min-height: $map-content-min-height;
    max-height: calc(100vh - #{$navbar-small-height + $map-content-offset-top + $map-content-offset-bottom});
    padding: $map-content-body-padding-top $map-content-body-padding-x 0;
    box-shadow: $map-content-box-shadow;
  }

  @include media-breakpoint-up($navbar-breakpoint-up) {
    max-height: calc(100vh - #{$navbar-height + $map-content-offset-top + $map-content-offset-bottom});
  }

  .navbar-promo-active & {
    max-height: calc(100vh - #{$navbar-small-computed-height + $map-content-offset-top + $map-content-offset-bottom});

    @include media-breakpoint-up($navbar-breakpoint-up) {
      max-height: calc(100vh - #{$navbar-computed-height + $map-content-offset-top + $map-content-offset-bottom});
    }
  }
}

.map-content-inner-no-gutters {
  @include media-breakpoint-up(md) {
    margin-left: -$map-content-body-padding-x;
    margin-right: -$map-content-body-padding-x;
  }
}

.map-floating-marker {
  position: absolute;
  margin: $map-floating-marker-height / 2 $map-floating-marker-width / 2;
  width: $map-floating-marker-width;
  height: $map-floating-marker-height;
  background-image: url('../../img/store-locator/icon-marker-circle.svg');
  padding-top: $map-floating-marker-height / 2;
  font-size: $map-floating-marker-font-size;
  text-align: center;
  color: $map-floating-marker-color;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.region-paths {
  cursor: pointer;

  &:hover,
  &.active {
    > path {
      fill: #00d1d2;
    }
  }

  > path {
    transition: fill 0.15s ease-in-out;
  }
}
