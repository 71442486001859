// Contextual colors
@each $color, $value in $main-colors {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $color, $value in $main-colors {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .text#{$infix}-#{$color} {
        color: $value !important;
      }
    }
  }
}

@each $color, $value in $main-colors {
  a.text-hover-#{$color} {
    @include hover-focus() {
      color: $value !important;
    }
  }
}

.text-transparent {
  color: transparent !important;
}

// Responsive font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .font-size#{$infix}-extra-extra-large {
      font-size: $font-size-extra-extra-large !important;
    }

    .font-size#{$infix}-extra-large {
      font-size: $font-size-extra-large !important;
    }

    .font-size#{$infix}-large {
      font-size: $font-size-large !important;
    }

    .font-size#{$infix}-body {
      font-size: $font-size-body !important;
    }

    .font-size#{$infix}-small {
      font-size: $font-size-small !important;
    }

    .font-size#{$infix}-extra-small {
      font-size: $font-size-extra-small !important;
    }

    .font-size#{$infix}-extra-extra-small {
      font-size: $font-size-extra-extra-small !important;
    }
  }
}

// Responsive font weights
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .font-weight#{$infix}-bold {
      font-weight: $font-weight-bold !important;
    }

    .font-weight#{$infix}-normal {
      font-weight: $font-weight-normal !important;
    }

    .font-weight#{$infix}-light {
      font-weight: $font-weight-light !important;
    }
  }
}

.text-strikethrough {
  text-decoration: line-through !important;
}

.line-height-none {
  line-height: 1 !important;
}

.line-height-small {
  line-height: $line-height-small !important;
}

.line-height-base {
  line-height: $line-height-base !important;
}

.line-height-large {
  line-height: $line-height-large !important;
}

.letter-spacing-small {
  letter-spacing: $letter-spacing-small !important;
}

.letter-spacing-nsmall {
  letter-spacing: $letter-spacing-nsmall !important;
}

.letter-spacing-large {
  letter-spacing: $letter-spacing-large !important;
}

// Button height text (to vertically align with buttons in different containers)
.text-btn-height {
  display: inline-flex;
  align-items: center;
  min-height: $btn-height;
}
