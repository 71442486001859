.scan-it-block {
  font-family: $font-family-base;
  flex-direction: column;

  header {
    padding: 32px 0;
  }

  .line {
    border: solid 1px #e8e8e8;
    width: 100%;
    height: 1px;
  }

  .separator {
    margin-bottom: 24px;
  }

  .welcome-row {
    p {
      font-size: 11px;
      text-align: center;
      color: #a9a092;
      padding-top: 30px;
      text-transform: uppercase;
      margin: 0;
      font-weight: 600;
    }

    p.slogan {
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.75;
      letter-spacing: -0.06px;
      text-align: center;
      color: #272a33;
      text-transform: none;
      margin-bottom: 8px;
      padding-top: 0;
    }
  }

  .img-wrapper {
    &.ico-app {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 33px;
        height: 36px;
      }
    }

    &.flavors-dce {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 54px;
        height: 36px;
      }
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }

  .btn-row {
    max-width: 425px;
    margin: 0 auto 0;

    > div {
      margin-bottom: 15px;
    }

    a {
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      color: #272a33;
    }
  }

  footer {
    padding: 72px 0;
    text-align: center;
    font-size: 12px;
    color: #4f4f4f;
    background: #f6f6f6;
  }
}
