.col-with-arrow {
  @include media-breakpoint-up(lg) {
    &:not(:last-child) {
      padding-right: 2rem;
    }
    &:not(:first-child) {
      padding-left: 2rem;
    }
  }

  &:not(:last-child) {
    &::after {
      @extend %nbw-icon;
      display: inline-block;
      position: absolute;
      bottom: -30px;
      font-size: inherit;
      line-height: inherit;
      content: $nbw-icon-arrow-down;

      @include media-breakpoint-up(lg) {
        bottom: auto;
        top: 50%;
        right: 0;
        content: $nbw-icon-arrow-right-fat;
        transform: translate3d(50%, -50%, 0);
      }
    }
  }
}
