@media (hover: hover) and (pointer: fine) {
  * {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;

    &::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-color;
      border-radius: 6px;
      border: 3px solid $scrollbar-track-color;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar-track-color;
    }
  }
}
