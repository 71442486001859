%pointer-active {
  .pointer {
    &::before {
      background-color: $pointer-bg-active;
      transform: scale3d(1, 1, 1);
    }

    &::after {
      @include media-breakpoint-up(lg) {
        opacity: 0;
        transform: scale3d(1, 1, 1);
      }
    }
  }

  .pointer-line {
    @include media-breakpoint-up(lg) {
      transform: scale3d(1, 1, 1);
    }
  }
}

.pointer {
  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    @extend %nbw-icon;
    display: block;
    border-radius: 50%;
    width: $pointer-width;
    height: $pointer-height;
    margin-left: auto;
    margin-right: auto;
    background-color: $pointer-bg;
    padding: ($pointer-width - $font-size-base) / 2;
    text-align: center;
    content: $nbw-icon-plus;
    transform: scale3d(0.8, 0.8, 1);
    transition-property: background-color, transform;
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    @include media-breakpoint-up(lg) {
      background-color: $pointer-bg-active;
      transform: scale3d(0, 0, 1);
    }
  }

  &::after {
    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: ($pointer-height - $pointer-outline-height) / 2;
      left: ($pointer-width - $pointer-outline-width) / 2;
      opacity: 1;
      border: $pointer-outline-border-width solid $pointer-outline-border-color;
      border-radius: 50%;
      width: $pointer-outline-width;
      height: $pointer-outline-height;
      content: '';
      transform: scale3d(0, 0, 1);
      transition-property: opacity, transform;
      transition-duration: 0.75s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

.pointer-line {
  @include media-breakpoint-up(lg) {
    z-index: -1;
    display: block;
    position: absolute;
    top: 50%;
    border: none;
    height: $pointer-line-height;
    background-color: $pointer-line-color;
    transform: scale3d(0, 1, 1);
    transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.pointer-line-left {
  @include media-breakpoint-up(lg) {
    right: 50%;
    left: $pointer-line-offset;
    transform-origin: right center;
  }
}

.pointer-line-right {
  @include media-breakpoint-up(lg) {
    right: $pointer-line-offset;
    left: 50%;
    transform-origin: left center;
  }
}
