.block-with-big-centered-image-wrapper {
  &::before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
    background-color: inherit;
    content: '';
  }
}

.block-with-big-centered-image-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include media-breakpoint-up(lg) {
    top: auto;
    right: 3rem;
    bottom: 3rem;
    left: auto;
  }
}
