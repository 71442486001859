@mixin button-variant($bg, $active-bg, $disabled-color, $disabled-bg) {
  background-color: $bg;
  color: color-yiq($bg);

  &::before {
    background-color: $active-bg;
  }

  &:hover,
  &:focus,
  &.focus {
    background-color: transparent;
    color: color-yiq($active-bg);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($active-bg, 0.5);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $disabled-bg;
    color: $disabled-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: transparent;
    color: color-yiq($active-bg);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($active-bg, 0.5);
    }
  }
}

@mixin button-outline-variant($color, $active-bg, $disabled-color, $disabled-bg) {
  box-shadow: inset 0 0 0 $btn-border-width $color;
  background-color: transparent;
  color: $color;

  &::before {
    background-color: $active-bg;
  }

  &:hover {
    box-shadow: inset 0 0 0 $btn-border-width transparent;
    color: color-yiq($active-bg);
  }

  &:focus,
  &.focus {
    box-shadow: inset 0 0 0 $btn-border-width transparent, 0 0 0 $btn-focus-width rgba($active-bg, 0.5);
    color: color-yiq($active-bg);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    box-shadow: inset 0 0 0 $btn-border-width transparent;
    background-color: $disabled-bg;
    color: $disabled-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    box-shadow: inset 0 0 0 $btn-border-width transparent;
    color: color-yiq($active-bg);

    &:focus {
      box-shadow: inset 0 0 0 $btn-border-width transparent, 0 0 0 $btn-focus-width rgba($active-bg, 0.5);
    }
  }
}

@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  @include border-radius($border-radius, 0);
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;

  &.btn-circular {
    width: $line-height * $font-size + $padding-y * 2;
  }
}
