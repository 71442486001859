.quantity-input {
  margin: 0;
  border: 0;
  background: none;
  padding: 0 0.5rem;
  width: 3rem;
  line-height: $line-height-base;
  font-size: $font-size-body;
  font-family: $font-family-base;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
  }
}
