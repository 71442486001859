.ordered-list-large {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: counter;

  li {
    position: relative;
    margin-bottom: 1.5rem;
    padding-left: $ordered-list-bullet-width + $ordered-list-bullet-gap-small;

    @include media-breakpoint-up(lg) {
      padding-left: $ordered-list-bullet-width + $ordered-list-bullet-gap;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      width: $ordered-list-bullet-width;
      height: $ordered-list-bullet-height;
      background-color: $ordered-list-bullet-background;
      padding: (($ordered-list-bullet-height - $font-size-base * $line-height-base) / 2) 0;
      text-align: center;
      font-weight: $font-weight-bold;
      color: $ordered-list-bullet-color;
      counter-increment: counter;
      content: counter(counter);
    }

    picture {
      margin-left: -$ordered-list-bullet-width - $ordered-list-bullet-gap-small;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }
  }
}
