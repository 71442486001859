.buorg {
  z-index: $zindex-popover;
  color: $white;
  background-color: $gray-800;
  border: 0;
  font-family: $font-family-base;
  font-size: $font-size-body;
}

.buorg-pad {
  @extend .px-3, .py-3;
  line-height: $line-height-base;
}

.buorg-buttons {
  @extend .mt-3;
}

#buorgul,
#buorgpermanent {
  @extend .btn, .mx-2;
  @include button-variant($light, $accent, $gray-300, $gray-600);
}

#buorgig {
  @extend .btn, .mx-2;
  @include button-outline-variant($light, $accent, $gray-300, $gray-600);
}
