.horizontal-divider {
  color: $gray-dark;
  display: table;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  text-align: center;

  &::after,
  &::before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQoAAAACCAYAAACg75IQAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAiSURBVHgB7cABDQAgCATAt38g3QwHCRgF7hKAxXn3VwBm1St7BCqA7w3mAAAAAElFTkSuQmCC');
  }

  &::before {
    background-position: right 1em top 50%;
  }

  &::after {
    background-position: left 1em top 50%;
  }
}
