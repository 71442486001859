@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .btn#{$infix}-block {
      display: block;
      width: 100%;
    }

    .btn#{$infix}-inline {
      display: inline-block;
      width: auto;
    }
  }
}
