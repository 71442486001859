.min-w-0 {
  min-width: 0 !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.flex-basis-0 {
  flex-basis: 0;
}

@for $i from 0 through 10 {
  .min-height-#{$i*100} {
    min-height: $i * 100px !important;
  }
}
@for $i from 0 through 10 {
  .min-height-lg-#{$i*100} {
    @include media-breakpoint-up(lg) {
      min-height: $i * 100px !important;
    }
  }
}
