.block-milestone-slider-milestone-line {
  @include media-breakpoint-down(lg) {
    padding: 0 2rem;
  }

  .swiper-slide {
    width: 2.5rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      width: 9.375rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 1.25rem;
      left: 50%;
      width: 0.313rem;
      height: 0.313rem;
      border-radius: 100%;
      background-color: $accent;
      transform: translate3d(-50%, 0, 0);
      transition: transform 0.3s, background-color 0.3s;

      @include media-breakpoint-up(md) {
        top: 0.313rem;
      }
    }

    &:hover {
      &::before {
        transform: translate3d(-50%, 0, 0) scale3d(2.6, 2.6, 1);
      }
    }

    p {
      visibility: hidden;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-top: 2.5rem;
        visibility: visible;
      }
    }
  }

  .swiper-slide-thumb-active {
    &::before {
      transform: translate3d(-50%, 0, 0) scale3d(2.6, 2.6, 1);
      background-color: $light;
    }
  }
}

.block-milestone-slider-milestone {
  z-index: 4;

  @include media-breakpoint-down(lg) {
    padding: 0 2rem;
  }

  @include media-breakpoint-up(md) {
    max-width: 43.5rem;
  }
}

.block-milestone-slider-title {
  max-width: 17rem;

  @include media-breakpoint-down(lg) {
    padding: 0 2rem;
  }

  @include media-breakpoint-up(md) {
    max-width: 25rem;
  }
}

.block-milestone-slider-container {
  max-width: 85.875rem;
  min-height: 41rem;

  @include media-breakpoint-up(md) {
    min-height: 48rem;
  }
}

.block-milestone-slider-images {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 23rem;
  width: 15.625rem;
  height: 15.625rem;

  @include media-breakpoint-up(md) {
    top: -3rem;
    right: -2rem;
    width: 45.5rem;
    height: 45.5rem;
    opacity: 0.6;
  }

  @include media-breakpoint-up(xl) {
    opacity: 1;
  }

  .shape {
    position: absolute;
    transform: translate3d(-26%, -32%, 0) scale3d(0.45, 0.45, 1);

    @include media-breakpoint-up(md) {
      bottom: 19rem;
      transform: translate3d(0, 42%, 0) scale3d(2, 2, 1);
      width: 46.875rem;
      height: 23.375rem;
    }
  }

  .milestone-img {
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate3d(-100%, 65%, 0) scale3d(1, 1, 1);
      transition: transform 0.4s, opacity 0.4s;
      height: $milestone-image-height;
      width: $milestone-image-height;
      border-radius: $milestone-image-height * 2;
      opacity: 0;

      @include media-breakpoint-up(md) {
        transform: translate3d(-50%, 25%, 0) scale3d(0.75, 0.75, 1);
        width: $milestone-large-image-height;
        height: $milestone-large-image-height;
      }
    }

    &.visible {
      img {
        transform: translate3d(-30%, 30%, 0) scale3d(1.9, 1.9, 1);
        z-index: 2;
        opacity: 1;

        @include media-breakpoint-up(md) {
          transform: translate3d(-5%, 10%, 0) scale3d(1, 1, 1);
        }
      }

      ~ picture img {
        transform: scale(3.1) translate(95%, -35%);

        @include media-breakpoint-up(md) {
          transform: scale(2.1) translate(85%, -35%);
        }
      }

      + picture img {
        transform: scale(2.9) translate(54%, -22%);
        z-index: 1;
        opacity: 0.6;

        @include media-breakpoint-up(md) {
          transform: scale(1.9) translate(55.5%, -21%);
        }
      }
    }
  }
}
