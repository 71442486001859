.dot {
  display: block;
  border-radius: 100%;
  width: $dot-size;
  height: $dot-size;
  background-color: $dot-color;

  &.dot.dot-lg {
    width: $dot-lg-size;
    height: $dot-lg-size;
  }
}

.dots-group {
  display: inline-flex;
  flex-wrap: nowrap;

  .dot + .dot {
    margin-left: $dots-group-dot-spacer;
  }

  &.dots-group-narrow {
    .dot + .dot {
      margin-left: $dots-group-dot-narrow-spacer;
    }
  }
}

.dots-pill {
  display: inline-flex;
  border-radius: $dots-pill-border-radius;
  background-color: $dots-pill-bg;
  padding: $dots-pill-padding-x $dots-pill-padding-y;
  color: $dots-pill-dot-color;

  .dot {
    opacity: $dots-pill-dot-opacity;
    background-color: currentColor;
    width: $dots-pill-dot-size;
    height: $dots-pill-dot-size;

    + .dot {
      margin-left: $dots-pill-dot-spacer;
    }
  }

  .dot-active {
    opacity: $dots-pill-dot-active-opacity;
  }
}

.dots-pill-opaque {
  background-color: $dots-pill-opaque-bg;

  .dot {
    background-color: $dots-pill-opaque-dot-color;
  }
}
