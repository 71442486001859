.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
  overflow: hidden;
  position: relative;
  vertical-align: initial;
  border: 0;
  transition: $btn-transition;

  &::before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: -$btn-skew-width-increase / 2;
    transform: translate3d(-100%, 0, 0) skew($btn-skew-degrees);
    width: calc(100% + #{$btn-skew-width-increase});
    height: 100%;
    content: '';
    transition: transform 0.25s ease-out;
  }

  &:hover,
  &:focus,
  &.focus &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    transition: $btn-hover-transition;

    &::before {
      transform: translate3d(0, 0, 0) skew($btn-skew-degrees);
    }
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    transition: $btn-disabled-transition;

    &::before {
      transform: translate3d(-100%, 0, 0) skew($btn-skew-degrees);
    }
  }

  .nbw-icon {
    vertical-align: bottom;
    font-size: inherit;
    line-height: inherit;
  }
}

// Color variants
@each $color, $value in $btn-colors {
  .btn-#{$color} {
    @include button-variant(
      map-get($value, 'bg'),
      map-get($value, 'active-bg'),
      map-get($value, 'disabled-color'),
      map-get($value, 'disabled-bg')
    );
  }

  .btn-outline-#{$color} {
    @include button-outline-variant(
      map-get($value, 'bg'),
      map-get($value, 'active-bg'),
      map-get($value, 'disabled-color'),
      map-get($value, 'disabled-bg')
    );
  }
}

.btn-arrowed-left,
.btn-arrowed-right {
  &:not(.loading)::after {
    @extend %nbw-icon;
    display: inline-block;
    line-height: inherit;
    transition: transform 0.25s ease-in-out;
  }
}

.btn-arrowed-left:not(.loading) {
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      &::after {
        transform: translateX(-$btn-arrow-gutter);
      }
    }
  }

  &::after {
    float: left;
    padding-right: $btn-arrow-gutter;
    content: $nbw-icon-arrow-left-fat;
  }
}

.btn-arrowed-right:not(.loading) {
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      &::after {
        transform: translateX($btn-arrow-gutter);
      }
    }
  }

  &::after {
    float: right;
    padding-left: $btn-arrow-gutter;
    content: $nbw-icon-arrow-right-fat;
  }
}

// Responsive button sizes
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .btn#{$infix}-extra-small {
      @include button-size(
        $btn-padding-y-extra-small,
        $btn-padding-x-extra-small,
        $btn-font-size-extra-small,
        $btn-line-height-extra-small,
        $btn-border-radius-extra-small
      );
    }

    .btn#{$infix}-small {
      @include button-size(
        $btn-padding-y-small,
        $btn-padding-x-small,
        $btn-font-size-small,
        $btn-line-height-small,
        $btn-border-radius-small
      );
    }

    .btn#{$infix}-default {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    }

    .btn#{$infix}-large {
      @include button-size(
        $btn-padding-y-large,
        $btn-padding-x-large,
        $btn-font-size-large,
        $btn-line-height-large,
        $btn-border-radius-large
      );
    }
  }
}

// Circular button
.btn-circular {
  padding-left: 0;
  padding-right: 0;
}

.btn-collapse-plus {
  height: 2.125rem;
  width: 2.125rem;
  border: 2px solid $dark;
  color: $dark;
  border-radius: 50%;
  position: relative;
  background: none;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(0);
    width: 0.7rem;
    border-top: 2px solid;
  }

  &::before {
    transition: transform 0.3s ease-in-out;
  }

  &.active::before {
    transform: translate3d(-50%, -50%, 0) rotate(180deg);
  }

  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(90deg);
    transition: transform 0.5s ease-in-out;
  }

  &.active::after {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }

  &:focus-visible {
    outline-offset: 5px;
  }
}

.btn-with-min-width {
  min-width: $btn-min-width;
}
