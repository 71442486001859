.link-block-with-image {
  height: calc(66.7vw - 2.5rem);

  @include media-breakpoint-up(sm) {
    height: calc(49.7vw - 2.5rem);
  }

  @include media-breakpoint-up(md) {
    height: calc(38vw - 2.375rem);
  }

  @include media-breakpoint-up(lg) {
    height: 14.5rem;
  }

  img {
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
