.reveal-when-visible {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: $accent;
    width: 150%;
    height: 100%;
    z-index: 2;
    transform: translate3d(0, 0, 0) skew(-21.5deg);
    transform-origin: top right;
    transition: opacity 0.3s 1.3s, transform 1.3s;
  }

  &.revealed {
    &::before {
      transform: translate3d(100%, 0, 0) skew(-21.5deg);
      opacity: 0;
    }
  }

  .lazy {
    transition: none;
  }
}
