.chip-group {
  overflow-x: auto;
  white-space: nowrap;

  .btn {
    + .btn {
      margin-left: 1rem;

      @include media-breakpoint-up(lg) {
        margin-left: 1.5rem;
      }
    }

    &:first-child {
      margin-left: 2rem;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 2rem;

      @include media-breakpoint-up(lg) {
        margin-right: 0;
      }
    }
  }
}
