.block-blocks-slider-slide {
  width: 100%;
  max-width: 13.75rem;

  @include media-breakpoint-up(sm) {
    max-width: 22.5rem;
  }

  @include media-breakpoint-up(lg) {
    max-width: 32.5rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;
    }
  }

  figure {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(360deg, $black 0%, rgba($black, 0) 100%);
      opacity: 0.6;
    }
  }

  h3 {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 1;

    @include media-breakpoint-up(md) {
      line-height: $line-height-base;
      font-weight: $font-weight-bold;
      bottom: 2.5rem;
      left: 2.5rem;
      right: 2.5rem;
    }
  }
}
