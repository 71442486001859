.range-linear {
  display: flex;

  .range-linear-block {
    background-color: $gray-extra-light;
    height: $linear-range-item-height;
    width: $linear-range-item-width;
    margin-right: $linear-range-item-space;

    &:first-child {
      border-bottom-left-radius: $linear-range-item-radius;
      border-top-left-radius: $linear-range-item-radius;
    }

    &:last-child {
      border-bottom-right-radius: $linear-range-item-radius;
      border-top-right-radius: $linear-range-item-radius;
    }
  }
}

.range-circular {
  width: $circular-range-width;
  height: $circular-range-height;
  overflow: hidden;
  position: relative;

  &.range-circular-small {
    width: $circular-range-small-width;
    height: $circular-range-small-height;

    .range-circular-part {
      width: $circular-range-part-small-width;
      height: $circular-range-part-small-height;
      border: $circular-range-border;
      border-radius: $circular-range-small-radius;
      position: absolute;
      clip: $circular-range-small-part-clip;
      z-index: 2;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transform: rotate($i * $circular-range-small-part-angle);
        }
      }
    }

    .range-circular-bg {
      width: $circular-range-small-width;
      height: $circular-range-small-width;
      border-radius: $circular-range-small-radius;
      border: $circular-range-background-border;
      clip: rect(auto, auto, auto, auto);
      z-index: 1;
    }
  }

  .range-circular-part {
    width: $circular-range-part-width;
    height: $circular-range-part-height;
    border: $circular-range-border;
    border-radius: $circular-range-border-radius;
    position: absolute;
    clip: $circular-range-part-clip;
    z-index: 2;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transform: rotate($i * $circular-range-part-angle);
      }
    }
  }

  .range-circular-bg {
    width: $circular-range-width;
    height: $circular-range-width;
    border-radius: $circular-range-border-radius;
    border: $circular-range-background-border;
    clip: rect(auto, auto, auto, auto);
    z-index: 1;
  }

  .range-circular-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
