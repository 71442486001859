* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  // Base font size
  font-size: 16px;
}

body {
  font-size: $font-size-body;
}

// Type display classes
.display-1 {
  @include font-size($display1-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($display1-size-medium);
  }

  @include media-breakpoint-up($headings-large-breakpoint) {
    @include font-size($display1-size-large);
  }
}

.display-2 {
  @include font-size($display2-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($display2-size-medium);
  }

  @include media-breakpoint-up($headings-large-breakpoint) {
    @include font-size($display2-size-large);
  }
}

.display-3 {
  @include font-size($display3-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($display3-size-medium);
  }

  @include media-breakpoint-up($headings-large-breakpoint) {
    @include font-size($display3-size-large);
  }
}

.display-4 {
  @include font-size($display4-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($display4-size-medium);
  }

  @include media-breakpoint-up($headings-large-breakpoint) {
    @include font-size($display4-size-large);
  }
}

h1,
.h1 {
  @include font-size($h1-font-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($h1-font-size-large);
  }
}

h2,
.h2 {
  @include font-size($h2-font-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($h2-font-size-large);
  }
}

h3,
.h3 {
  @include font-size($h3-font-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($h3-font-size-large);
  }
}

h4,
.h4 {
  @include font-size($h4-font-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($h4-font-size-large);
  }
}

h5,
.h5 {
  @include font-size($h5-font-size);

  @include media-breakpoint-up($headings-medium-breakpoint) {
    @include font-size($h5-font-size-large);
  }
}

hr {
  border-top-color: $border-color;
}
