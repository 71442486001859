.select2-container--nbw {
  width: 100% !important;

  .select2-selection {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    box-shadow: $input-box-shadow;
    transition: $input-transition;

    &:focus {
      outline: 0;
    }
  }

  .select2-selection--single {
    height: $input-height;

    .select2-selection__placeholder {
      color: $input-placeholder-color;
    }

    .select2-selection__rendered {
      padding: $input-padding-y $input-padding-x + $input-icon-font-size + $input-icon-padding $input-padding-y
        $input-padding-x;
    }

    .select2-selection__arrow {
      position: absolute;
      top: $input-padding-y - (($input-icon-font-size - $input-font-size) / 2);
      right: $input-padding-x;

      b {
        @extend %nbw-icon;
        display: block;
        line-height: inherit;
        transform: scaleY(1);
        transition: transform 0.2s linear;
        font-size: $input-icon-font-size;

        &:before {
          content: $nbw-icon-chevron-down;
        }
      }
    }
  }

  &.select2-container--focus,
  &.select2-container--open {
    .select2-selection {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transform: scaleY(-1);
  }

  &.select2-container--disabled {
    pointer-events: none;

    ~ label {
      color: $input-disabled-color;
    }

    .select2-selection {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
      box-shadow: none;
    }

    .select2-search__field {
      background-color: transparent;
    }

    .select2-selection--single {
      .select2-selection__rendered {
        color: $input-disabled-color;
      }

      .select2-selection__placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }

  .select2-search {
    // fixes placeholder width
    width: 100%;
  }

  .select2-dropdown {
    z-index: $zindex-dropdown;
    border: $input-border-width * 2 solid $input-focus-border-color;
    border-radius: $input-border-radius;
    background-color: $input-bg;

    &.select2-dropdown--below {
      border-top-color: $input-bg;

      .select2-search--dropdown {
        top: -$input-height;
      }
    }

    &.select2-dropdown--above {
      border-bottom-color: $input-bg;

      .select2-search--dropdown {
        bottom: -$input-height;
      }
    }

    .select2-search--dropdown {
      position: absolute;
      padding: $input-padding-y - px-to-rem($input-border-width) $input-padding-x - px-to-rem($input-border-width) +
        $input-icon-font-size + $input-icon-padding $input-padding-y - px-to-rem($input-border-width) $input-padding-x -
        px-to-rem($input-border-width);
    }

    .select2-search__field {
      display: block;
      margin: 0;
      border: 0;
      padding: 0;
      vertical-align: middle;
      white-space: normal;
      background-color: $input-bg;
      font-family: $input-font-family;
      font-size: $input-font-size;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;

      &:focus {
        outline: 0;
      }
    }

    .modal-open & {
      z-index: $zindex-popover;
    }
  }

  .select2-results__option--highlighted {
    &,
    &.select2-results__option[aria-selected='true'] {
      background-color: $gray-200;
    }
  }

  .select2-results__option {
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;

    &[aria-disabled='true'] {
      display: none;
    }
  }

  .select2-results > .select2-results__options {
    max-height: ($input-padding-y * 2 + $input-font-size * $input-line-height) * $input-dropdown-max-lines;
    overflow-y: auto;
  }
}
