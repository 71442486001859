.swiper-container.swiper-container-next-slide-visible-on-mobile {
  .swiper-slide {
    @include media-breakpoint-down(md) {
      width: calc(100% - #{$grid-gutter-width * 2}) !important;
    }
  }
}

.swiper-container-next-slide-visible-on-mobile-with-2-slides {
  .swiper-slide {
    @include media-breakpoint-down(md) {
      max-width: calc(50% - #{$grid-gutter-width / 2}) !important;
    }
  }
}
