.block-with-offset-image-wrapper {
  @include media-breakpoint-up(lg) {
    &::before {
      background-color: inherit;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50%;
      width: 50vw;
    }
  }

  &.block-with-offset-inverse {
    @include media-breakpoint-up(lg) {
      &::after {
        background-color: $white;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 75%;
        width: 25vw;
      }
    }
  }
}

.block-with-offset-image-image-container {
  .order-lg-1 & {
    margin-left: -2rem;
  }
}

.block-with-offset-image-image-container {
  @include media-breakpoint-up(lg) {
    top: 4rem;
  }

  @include media-breakpoint-up(xl) {
    top: 6rem;
  }

  &.block-with-offset-top {
    @include media-breakpoint-up(lg) {
      top: -4rem;
    }

    @include media-breakpoint-up(xl) {
      top: -6rem;
    }
  }
}

.block-with-offset-image-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
