.block-image-with-content-slider-swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}

.block-image-with-content-slider-swiper-navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.block-image-with-content-slider-slide-text-block {
  max-width: 31.25rem;
}
