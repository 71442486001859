.floating-message {
  z-index: $zindex-fixed;
  position: fixed;
  left: $floating-message-margins-mobile;
  right: $floating-message-margins-mobile;
  bottom: $floating-message-margins-mobile;
  overflow-y: auto;
  border-radius: $floating-message-border-radius;
  padding: $floating-message-y-padding-mobile $floating-message-x-padding-mobile;

  @include media-breakpoint-up($floating-message-breakpoint-up) {
    left: auto;
    right: $floating-message-offset-right;
    bottom: $floating-message-offset-bottom;
    box-shadow: $floating-message-box-shadow;
    width: $floating-message-width;
    padding: $floating-message-y-padding $floating-message-x-padding;
  }
}
