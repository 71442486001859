.scene-progress-bar {
  overflow: hidden;
  position: relative;
  display: block;
  margin-top: $scene-progress-bar-gap-small;
  margin-bottom: $scene-progress-bar-gap-small;
  height: $scene-progress-bar-height-small;
  width: $scene-progress-bar-width;

  @include media-breakpoint-up(md) {
    margin-top: $scene-progress-bar-gap;
    margin-bottom: $scene-progress-bar-gap;
    height: $scene-progress-bar-height;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.active {
    &::before {
      transform: translate3d(0, 0, 0);
    }
  }

  .scene-progress-dark & {
    background-color: $scene-progress-bar-dark-bg;

    &::before {
      background-color: $scene-progress-bar-dark-active-bg;
    }
  }

  .scene-progress-light & {
    background-color: $scene-progress-bar-light-bg;

    &::before {
      background-color: $scene-progress-bar-light-active-bg;
    }
  }
}
