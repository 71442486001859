.container-narrow {
  @include make-container();

  @include media-breakpoint-up(lg) {
    max-width: $container-narrow-width;
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

.container-no-gutters {
  @include make-container(0);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .container#{$infix} {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }
  }
}
