:not(.modal-body) {
  .login-step-two {
    max-width: $container-extra-narrow-width;
    margin: auto;
  }
}

:not(.modal-body) {
  .login-step-two-back-button {
    max-width: $container-narrow-width;
  }
}

.login-step-two {
  .custom-radio {
    margin-bottom: 0;
  }
}
