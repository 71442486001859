.steps {
  .step-item {
    img {
      height: 3rem;
      width: 3rem;

      @include media-breakpoint-up(lg) {
        height: 4rem;
        width: 4rem;
      }
    }

    &.step-item-lg-small {
      @include media-breakpoint-up(lg) {
        img {
          height: 3rem;
          width: 3rem;
        }
      }
    }
  }
}
