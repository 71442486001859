.block-video-blocks-slide {
  width: 100%;
  max-width: 15.5rem;

  @include media-breakpoint-up(lg) {
    max-width: 16rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;

    @include media-breakpoint-up(lg) {
      margin-right: 4rem;
    }
  }

  h3 {
    position: absolute;
    bottom: 1.5rem;
    left: 1.25rem;
    z-index: 1;
  }

  .block-video-blocks-slide-button {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
