.paragraph-block {
  border: $paragraph-block-border-width solid $paragraph-block-border-color;
  padding: $paragraph-block-padding-y $paragraph-block-padding-x;
  background: $paragraph-block-background-color;
}

.paragraph-block-with-bg {
  padding: ($paragraph-block-padding-y * 2) $paragraph-block-padding-x;
  border-radius: $paragraph-block-border-radius;
}

@each $color, $value in $paragraph-colors {
  .paragraph-block-with-bg-#{$color} {
    background-color: $value;
  }
}
