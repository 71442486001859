.radio-checker {
  display: flex;
  align-items: center;

  .radio-checker-button {
    background-color: $radio-checker-background;
    color: $custom-radio-indicator-checked-disabled-color;
    margin-right: 0.625rem;

    &.active {
      color: $radio-checker-font-color;
      background-color: $radio-checker-active-background;
      border-width: 0;
    }
  }

  .radio-checker-label {
    font-size: $radio-checker-font-size;
    color: $radio-checker-font-color;
  }
}

.radio-checker-list {
  list-style: none;
  padding: 0;
  margin: 0 0 0 1.5rem;

  > li {
    display: flex;
    align-items: center;
    padding: 0.3rem;
    color: $gray;

    p {
      margin: 0;
    }

    i {
      margin-right: 0.625rem;
      font-size: 0.7rem;
    }

    &.active {
      color: $body-color;

      i {
        color: $accent;

        &::before {
          content: $nbw-icon-check;
        }
      }
    }
  }
}
