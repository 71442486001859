.age-gate-modal {
  .form-floating {
    > label {
      @include media-breakpoint-up(lg) {
        overflow: visible;
      }
    }
  }

  .form-floating > label {
    color: $input-placeholder-dark-color !important;
  }
}
