@import 'profile-dashboard';

.delete-account-text {
  ul {
    padding-left: 1.2rem;
    li {
      margin-top: 0.8rem;
    }
  }
}

.profile-page-container {
  // Use !important to override default Bootstrap dropdown styles.
  .dropdown-menu {
    position: static !important;
    transform: initial !important;
  }
}

legend.close-account-reason-legend {
  margin-bottom: 2rem;
  font-weight: $font-weight-bold;
}
