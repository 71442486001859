.code-block {
  padding: $code-block-small-padding-y $code-block-small-padding-x;
  background: $code-block-background-color;
  width: 50%;

  @include media-breakpoint-up(sm) {
    width: auto;
    padding: $code-block-padding-y $code-block-padding-x;
  }
}
