.inline-video {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;

  &.playing,
  &.paused {
    opacity: 1;
  }
}

.inline-video-toggle {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none !important;

  .inline-video.playing + & {
    opacity: 0;
  }
}
