.checkout-order-overview {
  height: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    max-height: 320px;
  }

  .checkout-order-overview-item-image {
    width: auto;
    min-width: 108px;
    max-width: 116px;

    @include media-breakpoint-up(md) {
      min-width: 90px;
      max-width: 130px;
    }

    img {
      width: 92%;
      height: auto;
    }
  }
}

.checkout-courier-logo {
  height: 1.5625rem;
  width: auto;
}

.checkout-iqos-logo {
  height: 1rem;
  width: auto;
}

.checkout-coupon {
  min-height: 5rem;

  .nbw-icon-discount-coupon.circle-icon {
    padding-top: ($circle-icon-height - 1.5rem) / 2;
    padding-bottom: ($circle-icon-height - 1.5rem) / 2;
    font-size: 1.5rem;
  }
}
