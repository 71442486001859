.large-nav-block {
  margin: 0 auto;
  max-width: $large-nav-block-width;

  a {
    color: $gray-extra-dark;
    padding: $large-nav-block-item-padding-y $large-nav-block-item-padding-x;
  }

  .active {
    color: $dark;
    position: relative;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: $accent;
      height: $large-nav-block-item-border-width;
    }
  }
}
