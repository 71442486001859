// Buttons
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  transition: $swiper-button-transition;

  &::after {
    font-size: $swiper-button-font-size;
    @extend %nbw-icon;
  }

  &.btn {
    z-index: 10;

    &::after {
      font-size: $font-size-extra-large;
      line-height: $btn-font-size * $btn-line-height;
    }
  }
}

.swiper-button-prev {
  left: 0;

  &::after {
    content: $nbw-icon-chevron-left;
  }
}

.swiper-button-next {
  right: 0;

  &::after {
    content: $nbw-icon-chevron-right;
  }
}

.hero-slider-navigation,
.banner-slider-container {
  button {
    &.swiper-button-prev,
    &.swiper-button-next {
      border: none;
      background: none;
    }
  }
}

.swiper-button-light {
  color: $swiper-button-light-color;

  &:hover {
    color: $swiper-button-light-hover-color;
  }
}

.swiper-button-dark {
  color: $swiper-button-dark-color;

  &:hover {
    color: $swiper-button-dark-hover-color;
  }
}

// Pagination
.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  opacity: 1;
  width: $swiper-pagination-bullet-width;
  height: $swiper-pagination-bullet-height;
  transition: background-color 0.3s;

  &,
  .swiper-container-horizontal > .swiper-pagination-bullets & {
    margin: 0 $swiper-pagination-bullet-gap / 2;
  }

  .swiper-pagination-dark & {
    background-color: $swiper-pagination-dark-bg;

    &.swiper-pagination-bullet-active {
      background-color: $swiper-pagination-dark-active-bg;
    }
  }

  .swiper-pagination-light & {
    background-color: $swiper-pagination-light-bg;

    &.swiper-pagination-bullet-active {
      background-color: $swiper-pagination-light-active-bg;
    }
  }
}

.swiper-pagination-no-transition {
  .swiper-pagination-bullet {
    transition: none;
  }
}

.swiper-pagination-stretched {
  .swiper-pagination-bullet {
    border-radius: 0;
    width: $swiper-pagination-stretched-bullet-width-small;
    height: $swiper-pagination-stretched-bullet-height-small;

    @include media-breakpoint-up(lg) {
      width: $swiper-pagination-stretched-bullet-width;
      height: $swiper-pagination-stretched-bullet-height;
    }

    &,
    .swiper-container-horizontal > & {
      margin: 0 $swiper-pagination-stretched-bullet-gap-small / 2;

      @include media-breakpoint-up(lg) {
        margin: 0 $swiper-pagination-stretched-bullet-gap / 2;
      }
    }
  }
}

.swiper-pagination-lock {
  display: none;
}

// Faded buttons
.swiper-buttons-navigation-faded {
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;

      &.swiper-button-disabled {
        opacity: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    &,
    .swiper-button-disabled {
      opacity: 0;
    }
  }
}

// Slide in buttons
.swiper-buttons-navigation-slide-in {
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      transform: translate3d(0, 0, 0);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    transition: $btn-transition, transform 0.3s;

    &:hover,
    &:focus {
      transition: $btn-hover-transition, transform 0.3s;
    }
  }

  .swiper-button-prev {
    transform: translate3d(0.5rem, 0, 0);
  }

  .swiper-button-next {
    transform: translate3d(-0.5rem, 0, 0);
  }
}

.swiper-buttons-container {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto;
}

.swiper-slide-fade-sideways {
  > * {
    transition: transform 0.3s;
  }

  &.swiper-slide-active > * {
    transform: translate3d(0, 0, 0);
  }

  &.swiper-slide-prev > * {
    transform: translate3d(-50px, 0, 0);
  }

  &.swiper-slide-duplicate-prev > * {
    transform: translate3d(50px, 0, 0);
  }

  &.swiper-slide-next > * {
    transform: translate3d(50px, 0, 0);
  }

  &.swiper-slide-duplicate-next > * {
    transform: translate3d(-50px, 0, 0);
  }
}
