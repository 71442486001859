%wysiwyg {
  img {
    max-width: 100%;
    height: auto;
  }

  h2,
  h3,
  h4 {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-top: 3.5rem;
      margin-bottom: 1.75rem;
    }
  }

  ul,
  ol {
    padding-left: 1.25rem;
    margin-bottom: 1.75rem;

    p,
    li {
      margin-bottom: 0.5rem;
    }
  }

  a {
    @extend .link-underlined;
  }

  table {
    width: 100% !important;
    border-collapse: collapse;
    border: 0;
    margin: 1.25rem 0;
  }

  th {
    background-color: $gray-extra-light;
    font-weight: bold !important;
    vertical-align: middle !important;
    border: 0 !important;
    padding: 1.25rem !important;
  }

  td {
    border: 0 !important;
    margin: 0.5rem 0 !important;

    @include media-breakpoint-up(lg) {
      padding: 1.25rem !important;
    }
  }

  thead {
    tr {
      padding: 1.25rem !important;

      @include media-breakpoint-up(lg) {
        padding: 0 !important;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #c4c4c4 !important;
      padding: 1rem 0 !important;

      @include media-breakpoint-up(lg) {
        padding: 0 !important;
      }
    }
  }

  th + th,
  td + td {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid #c4c4c4 !important;
    }
  }

  @include media-breakpoint-down(md) {
    table,
    tbody,
    th,
    td,
    tr {
      width: auto !important;
      height: auto !important;
    }

    th,
    td,
    tbody tr {
      display: block !important;
    }

    td:first-child,
    th:first-child {
      padding-bottom: 0 !important;
    }

    td + td,
    th + th {
      padding-top: 0 !important;
    }
  }
}

.wysiwyg {
  @extend %wysiwyg;
}
