.outline-on-focus {
  &:focus-visible {
    outline: $outline-color auto 1px;

    .inline-video,
    img {
      outline: $outline-color auto 1px;
    }

    &.stretched-link:after {
      outline: $outline-color auto 1px;
    }
  }

  &.outline-inside-on-focus:focus-visible {
    outline-offset: -1px;

    .inline-video,
    img {
      outline-offset: -1px;
    }
  }
}
