.sub-navbar-shadow {
  box-shadow: inset 0 9px 34px -40px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.sub-navbar {
  .navbar-promo-active & {
    top: $navbar-small-computed-height;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      top: $navbar-computed-height;
    }
  }

  .logo {
    width: 6.25rem;
    max-height: 2.375rem;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  .logo-image {
    width: 1.5rem;
    min-width: 1.5rem;

    @include media-breakpoint-up(lg) {
      width: 2.25rem;
      min-width: 2.25rem;
    }
  }

  .sub-navbar-header a {
    display: none;
    position: relative;
    pointer-events: none;
    padding: $sub-navbar-nav-link-small-padding-y $sub-navbar-nav-link-small-padding-x
      $sub-navbar-nav-link-small-padding-y 0;
    letter-spacing: $sub-navbar-nav-link-letter-spacing;

    @include media-breakpoint-up(xl) {
      display: flex;
      pointer-events: visible;
      padding: $sub-navbar-nav-link-padding-y $sub-navbar-nav-link-padding-x $sub-navbar-nav-link-padding-y 0;

      &:after {
        content: ' ';
        display: block;
        width: calc(100% - #{$sub-navbar-nav-link-padding-x});
        bottom: 0;
        left: 0;
        right: 0;
        background: $dark;
        height: 3px;
        position: absolute;
        transform: scale3d(0, 1, 1);
        transform-origin: center center;
        transition: transform 0.4s ease-out;
      }
    }

    &.active {
      display: block;
      text-overflow: ellipsis;
      max-width: 50vw;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-up(xl) {
        display: flex;
        font-weight: bold;

        &:after {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

  .sub-navbar-toggle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
    margin-bottom: 0;

    button {
      display: flex;
      border: 0;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: 0;
      align-items: center;
      justify-content: flex-end;

      &.active > .nbw-icon-chevron-down {
        transform: scaleY(-1);
      }

      .nbw-icon-chevron-down {
        transition: transform 0.2s linear;
      }
    }
  }
}

.sub-navbar-collapse {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  background-color: $navbar-bg;
  z-index: 1;

  @include media-breakpoint-down($sub-navbar-breakpoint-down) {
    &.show {
      display: flex;
    }
  }

  > .sub-navbar-collapse-body {
    max-height: calc(var(--vh, 1vh) * 100 - #{$sub-navbar-small-height} - #{$navbar-small-height});
    overflow-y: auto;

    .navbar-promo-active & {
      max-height: calc(var(--vh, 1vh) * 100 - #{$sub-navbar-small-height} - #{$navbar-small-computed-height});
    }
  }
}

.sub-navbar-collapse {
  @include media-breakpoint-down($sub-navbar-breakpoint-down) {
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease;

    .nav-link {
      opacity: 0;
      transform: translate3d(-2rem, 0, 0);
      transition: transform 0.3s ease, opacity 0.3s linear;
      transition-delay: 0.3s;
    }

    &.enter-to {
      transform: translate3d(0, 0, 0);

      .nav-link {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    &.leave-to {
      transition-delay: 0.3s;

      .nav-link {
        transition-delay: 0s;
      }
    }
  }
}

.sub-navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
  font-size: $navbar-font-size;
  padding: 1.5rem 2rem 2rem;

  .nav-link {
    display: flex;
    padding: 0;
    color: $navbar-color;
  }

  .nav-link-title-container {
    flex-grow: 1;
  }

  .nav-link-title {
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: $navbar-nav-link-title-small-font-size;
    font-weight: $navbar-nav-link-title-font-weight;
  }

  .nav-link-extra {
    font-size: $navbar-nav-link-extra-small-font-size;
  }

  > .nav-item {
    display: inline;

    > .nav-link {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      > .nav-link {
        margin-bottom: 0;
      }
    }
  }
}

.sub-navbar-header {
  position: relative;
  z-index: 2;
}

.sub-navbar-backdrop {
  @extend %backdrop;
  z-index: $zindex-sticky-nav-backdrop;
}

.sub-navbar-open {
  // Kill the scroll on the body
  overflow: hidden;
}
