.block-image-with-3-text-block-image-container {
  @include media-breakpoint-up(xl) {
    min-height: 35rem;
  }
}

.block-image-with-3-text-block-logo {
  max-width: 11.125rem;
}

.block-image-with-3-text-block-image-block {
  max-width: 35rem;
}

.block-image-with-3-text-block-bg-image {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  img {
    min-height: 19.5rem;
  }
}
