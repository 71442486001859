.list-group-item {
  border-left-width: 0;
  border-right-width: 0;

  &:active {
    background-color: $list-group-bg;
    color: $list-group-color;
  }

  &.active {
    background-color: $list-group-active-bg;
    color: $list-group-active-color;
  }
}

.list-group-item-action {
  transition: color 0.15s ease-out;
}

.list-group-item-arrowed-right {
  padding-right: $list-group-item-padding-x + $list-group-item-arrow-font-size;

  &::before {
    @extend %nbw-icon;
    position: absolute;
    top: 50%;
    right: $list-group-item-padding-x;
    transform: translate3d(0, -50%, 0);
    content: $nbw-icon-chevron-right;
    font-size: $list-group-item-arrow-font-size;
    transition: color 0.15s ease-out, transform 0.15s ease-in-out;
  }

  &.active {
    &::before {
      color: $list-group-active-arrow-color;
    }
  }

  &.list-group-item-action {
    &:hover,
    &:focus {
      &::before {
        transform: translate3d(3px, -50%, 0);
        color: $list-group-action-hover-arrow-color;
      }
    }

    &.active {
      &::before {
        color: $list-group-active-arrow-color;
      }
    }
  }
}

.list-group-padless {
  .list-group-item {
    padding-left: 0;
    padding-right: $list-group-item-arrow-font-size;

    &::before {
      right: 0;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .list-group-padded#{$infix} {
      .list-group-item {
        padding-left: $list-group-item-padding-x;
        padding-right: $list-group-item-padding-x + $list-group-item-arrow-font-size;

        &::before {
          right: $list-group-item-padding-x;
        }
      }
    }
  }
}
