.order-history-item {
  &.disabled {
    color: $gray;

    .text-red,
    .text-dark,
    .text-blue {
      color: inherit !important;
    }
  }
}
