ul,
ol {
  padding-left: 1.25rem;
  margin-bottom: 1.75rem;

  > li {
    margin-bottom: 0.5rem;

    &:last-child:not(:first-child) {
      margin-bottom: 0;
    }

    > ul,
    > ol {
      margin-top: 0.5rem;
    }
  }

  &.list-small {
    > li {
      margin-bottom: 0.25rem;

      &:last-child:not(:first-child) {
        margin-bottom: 0;
      }

      > ul,
      > ol {
        margin-top: 0.25rem;
      }
    }
  }
}
