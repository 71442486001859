.sticky-bar {
  z-index: $zindex-sticky;
  position: fixed;
  left: 0;
  right: 0;
  opacity: 0;
  transition: $sticky-bar-transition, opacity 0.15s $sticky-bar-transition-duration linear;

  &.sticky-bar-visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: $sticky-bar-transition;
  }

  .sticky-bar-image {
    width: $sticky-bar-image-height;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: $sticky-bar-image-height-lg;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .sticky-bar#{$infix}-top {
      top: $navbar-small-height;
      bottom: auto;
      transform: translate3d(0, -100%, 0);
      box-shadow: $sticky-bar-top-box-shadow;

      @include media-breakpoint-up($navbar-breakpoint-up) {
        top: $navbar-height;
      }

      .scroll-down & {
        transform: translate3d(0, -$navbar-small-height, 0) translate3d(0, -100%, 0);

        @include media-breakpoint-up($navbar-breakpoint-up) {
          transform: translate3d(0, -$navbar-height, 0) translate3d(0, -100%, 0);
        }

        &.sticky-bar-visible {
          transform: translate3d(0, -$navbar-small-height, 0);

          @include media-breakpoint-up($navbar-breakpoint-up) {
            transform: translate3d(0, -$navbar-height, 0);
          }
        }
      }

      .navbar-promo-active & {
        top: $navbar-small-computed-height;

        @include media-breakpoint-up($navbar-breakpoint-up) {
          top: $navbar-computed-height;
        }
      }
    }

    .sticky-bar#{$infix}-bottom {
      top: auto;
      bottom: 0;
      transform: translate3d(0, 100%, 0);
      box-shadow: $sticky-bar-bottom-box-shadow;
    }
  }
}
