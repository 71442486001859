.block-with-two-images-second-image {
  top: 2rem;
  margin-bottom: -3.5rem;

  @include media-breakpoint-up(xl) {
    margin-bottom: -1.5rem;
    top: 5rem;
  }
}

.block-with-two-images-main-image {
  @include media-breakpoint-up(lg) {
    .order-lg-1 & {
      margin-left: -2rem;
      margin-right: 3rem;
    }

    .order-lg-2 & {
      margin-left: 3rem;
      margin-right: -2rem;
    }
  }
}
