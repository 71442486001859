.overflow-x-auto {
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-lg-hidden {
  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }
}
