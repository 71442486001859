.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-top-right {
  top: 0;
  right: 0;
}

.absolute-top-left {
  top: 0;
  left: 0;
}

.absolute-bottom-right {
  bottom: 0;
  right: 0;
}

.position-xl-absolute {
  @include media-breakpoint-up(xl) {
    position: absolute;
  }
}

.absolute-xl-center-x {
  @include media-breakpoint-up(xl) {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-n1 {
  bottom: -1px !important;
}

.right-0 {
  right: 0 !important;
}

.z-index-popover {
  z-index: $zindex-popover;
}
