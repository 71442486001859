.block-with-uneven-images-bottom-upper-image {
  position: absolute;
  top: 0;
  left: 6rem;
  right: 0;
  z-index: 1;
  transform: translate3d(0, -8rem, 0);

  @include media-breakpoint-up(lg) {
    left: 5rem;
    transform: translate3d(0, -50%, 0);
  }

  @include media-breakpoint-up(xl) {
    left: 7rem;
  }
}

.block-with-uneven-images-bottom-lower-image {
  margin-right: 6rem;

  @include media-breakpoint-up(lg) {
    margin-right: 4rem;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 2rem;
  }
}
