.badge {
  padding-right: subtract($badge-padding-x, $badge-letter-spacing);

  &.badge-bigger {
    border-radius: $badge-bigger-border-radius;
    font-size: $badge-bigger-font-size;
    padding: $badge-bigger-padding-y $badge-bigger-padding-x;
  }
}

.badge-pill {
  padding-right: subtract($badge-pill-padding-x, $badge-letter-spacing);
}

@each $color, $value in $badge-colors {
  .badge-#{$color} {
    @include badge-variant($value);
    border: $badge-border-width solid $value;

    &.badge-outline {
      background-color: $light;
      color: $value;
    }
  }
}
