.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: $form-feedback-margin-top;
  @include font-size($form-feedback-font-size);
  color: $form-feedback-icon-invalid-color;
}

.is-invalid {
  ~ .invalid-feedback {
    display: block;
  }

  &.file-input-group > .invalid-feedback {
    display: block;
  }
}

.form-control {
  &.is-invalid {
    border-color: $form-feedback-invalid-color;
    box-shadow: inset 0 0 0 $input-border-width $form-feedback-invalid-color;

    &:not(.code-input-field) {
      @if $enable-validation-icons {
        padding-right: $input-padding-x + $form-feedback-icon-size + $form-feedback-icon-padding;
        background-image: escape-svg($form-feedback-icon-invalid);
        background-repeat: no-repeat;
        background-position: right $input-padding-x center;
        background-size: $form-feedback-icon-size;

        &:has(~ .form-control-append:not(.d-none)) {
          padding-right: $input-padding-x +
            $form-control-append-width +
            $form-control-append-padding +
            $form-feedback-icon-size +
            $form-feedback-icon-padding;
          background-position: right
            $input-padding-x +
            $form-control-append-width +
            $form-control-append-padding
            center;
        }

        &:has(~ .form-control-append.form-control-icon:not(.d-none)) {
          padding-right: $input-padding-x +
            $input-icon-font-size +
            $input-icon-padding +
            $form-feedback-icon-size +
            $form-feedback-icon-padding;
          background-position: right $input-padding-x + $input-icon-font-size + $input-icon-padding center;
        }
      }
    }

    &:not(:placeholder-shown) {
      border-color: $form-feedback-invalid-color;
    }

    &:focus {
      box-shadow: 0 0 0 $input-border-width $form-feedback-invalid-color,
        inset 0 0 0 $input-border-width $form-feedback-invalid-color;
    }
  }
}

.form-group.is-invalid {
  .custom-file {
    label {
      border-color: $form-feedback-invalid-color;
      box-shadow: inset 0 0 0 $input-border-width $form-feedback-invalid-color;
      padding-right: $input-padding-x + $form-feedback-icon-size + $form-feedback-icon-padding;
      background-image: escape-svg($form-feedback-icon-invalid);
      background-repeat: no-repeat;
      background-position: right $input-padding-x center;
      background-size: $form-feedback-icon-size;
    }

    .custom-file-input-label {
      color: $form-feedback-invalid-color;
    }
  }
}

textarea.form-control {
  &.is-invalid {
    @if $enable-validation-icons {
      background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
    }
  }
}

.custom-select {
  &.is-invalid {
    border-color: $form-feedback-invalid-color;
    box-shadow: inset 0 0 0 $input-border-width $form-feedback-invalid-color;

    @if $enable-validation-icons {
      padding-right: $custom-select-feedback-icon-padding-right;
      background: $custom-select-background,
        $custom-select-bg
          escape-svg($form-feedback-icon-invalid)
          $custom-select-feedback-icon-position /
          $custom-select-feedback-icon-size
          no-repeat;
    }

    &:focus {
      box-shadow: 0 0 0 $input-border-width $form-feedback-invalid-color,
        inset 0 0 0 $input-border-width $form-feedback-invalid-color;
    }
  }
}

.custom-control-input {
  &.is-invalid {
    &:checked {
      ~ .custom-control-label::before {
        .custom-radio & {
          border-color: $form-feedback-invalid-color;
          background-color: $custom-control-indicator-bg;
        }
      }
    }

    &:not(:checked) {
      ~ .custom-control-label::before {
        border-color: $form-feedback-invalid-color;
      }
    }
  }
}

.select2-hidden-accessible {
  &.is-invalid {
    ~ .select2-container--nbw {
      .select2-selection {
        border-color: $form-feedback-invalid-color;
        box-shadow: inset 0 0 0 $input-border-width $form-feedback-invalid-color;

        @if $enable-validation-icons {
          background-image: escape-svg($form-feedback-icon-invalid);
          background-repeat: no-repeat;
          background-position: right $input-padding-x + $input-icon-font-size + $input-icon-padding center;
          background-size: $form-feedback-icon-size;

          .select2-selection__rendered {
            padding-right: $input-padding-x +
              $form-feedback-icon-size +
              $form-feedback-icon-padding +
              $input-icon-font-size +
              $input-icon-padding;
          }
        }
      }

      &.select2-container--focus,
      &.select2-container--open {
        .select2-selection {
          box-shadow: 0 0 0 $input-border-width $form-feedback-invalid-color,
            inset 0 0 0 $input-border-width $form-feedback-invalid-color;
        }
      }
    }
  }
}

.form-floating {
  > .show-placeholder,
  > .form-control:focus,
  > .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible) {
    &.is-invalid {
      ~ label {
        color: $form-feedback-invalid-color;
      }
    }
  }

  &.is-invalid {
    > label {
      color: $form-feedback-invalid-color;
    }
  }

  > .select2-hidden-accessible:not(:disabled):not([readonly]) {
    &.is-invalid {
      ~ .select2-container--focus,
      ~ .select2-container--open,
      ~ .select2-container--selected {
        ~ label {
          color: $form-feedback-invalid-color;
        }
      }
    }
  }

  .select2-container--selected {
    & + .select-checkmark {
      opacity: 1;

      &::before {
        transform: translate(-50%, -50%) skew(-30deg);
        background-color: $green;
      }

      &::after {
        clip-path: inset(0 0 0 0);
        background-image: escape-svg($form-feedback-icon-checkmark);
      }
    }

    & + .select-clear {
      opacity: 1;
    }
  }
}

.select-clear {
  position: absolute;
  z-index: 1;
  top: 28%;
  right: $input-padding-x;
  width: $circle-icon-width-extra-small;
  font-size: $circle-icon-font-size-small;
  background-color: $white;
  opacity: 0;
  cursor: pointer;
  padding-left: 0.2rem;
}

.select-checkmark {
  position: absolute;
  z-index: 1;
  top: 25%;
  right: $input-padding-x;
  height: $circle-icon-height-small;
  width: $circle-icon-width-small;
  border-radius: 50%;
  background-color: transparent;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-150%, -50%) skew(-30deg);
    transition: transform 0.5s ease-out;
    height: 120%;
    width: 150%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: $circle-icon-height-small;
    width: $circle-icon-width-small;
    clip-path: inset(0 100% 0 0);
    background: transparent no-repeat center center;
    background-size: 100%;
    transition: clip-path 0.5s ease-in-out 0.2s;
  }
}
