@mixin circle-icon-size($width, $height, $font-size) {
  width: $width;
  height: $height;
  min-width: $width;
  padding-top: ($height - $font-size) / 2;
  padding-bottom: ($height - $font-size) / 2;
  font-size: $font-size;
}

.circle-icon {
  display: inline-block;
  border-radius: 50%;
  background-color: $circle-icon-bg;
  text-align: center;
  line-height: 1;
  color: $circle-icon-color;

  @include circle-icon-size($circle-icon-width, $circle-icon-height, $circle-icon-font-size);

  &.font-size-body {
    padding-top: ($circle-icon-height - $font-size-body) / 2;
    padding-bottom: ($circle-icon-height - $font-size-body) / 2;
  }
}

.circle-icon-small {
  @include circle-icon-size($circle-icon-width-small, $circle-icon-height-small, $circle-icon-font-size-small);
}

.circle-icon-extra-small {
  @include circle-icon-size(
    $circle-icon-width-extra-small,
    $circle-icon-height-extra-small,
    $circle-icon-font-size-extra-small
  );
}
