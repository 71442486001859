.collapsible-footer {
  transition: height 0.35s ease-out;

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.collapsible-footer-content {
  @include media-breakpoint-down(sm) {
    z-index: 1;
    overflow-y: auto;
    position: fixed;
    right: 0;
    bottom: $collapsible-footer-toggle-height;
    left: 0;
    max-height: calc(100% - #{$navbar-small-height + $collapsible-footer-toggle-height});
    background-color: $body-bg;
    font-size: $collapsible-footer-toggle-font-size;

    .navbar-promo-active & {
      max-height: calc(100% - #{$navbar-small-computed-height + $collapsible-footer-toggle-height});
    }
  }
}

.collapsible-footer-toggle {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: $collapsible-footer-toggle-border-width solid $collapsible-footer-toggle-border-color;
  background-color: $collapsible-footer-toggle-bg;
  padding-top: $collapsible-footer-toggle-padding-y;
  padding-bottom: $collapsible-footer-toggle-padding-y;
  font-size: $collapsible-footer-toggle-font-size;
  font-weight: $collapsible-footer-toggle-font-weight;
  color: $collapsible-footer-toggle-color;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
