.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-toggle {
  &::after {
    transition: transform 0.2s linear;

    &,
    .dropup & {
      @extend %nbw-icon;
      transform: scaleY(1);
      border: none;
      vertical-align: bottom;
      font-size: inherit;
      line-height: inherit;
      content: $nbw-icon-chevron-down;
    }

    .dropdown.show &,
    .dropup.show & {
      transform: scaleY(-1);
    }
  }
}
