.trade-in-device-select-count {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid $gray-dark;
  color: $gray-dark;
  background-color: $white;
  font-weight: $font-weight-bold;

  &.active {
    background-color: $dark;
    border-color: $dark;
    color: $white;
  }

  &.selected {
    background-color: $accent !important;
    border-color: $accent;
    color: $dark;

    .trade-in-device-select-count-text {
      display: none;
    }

    &:before {
      @extend %nbw-icon;
      content: $nbw-icon-check;
    }
  }
}

.trade-in-my-select-image {
  height: 5.25rem;
  width: 5.25rem;
}

.disabled-device {
  opacity: 0.5;
  pointer-events: none;
}

.trade-in-user-device-box {
  border-radius: 4px;
}
