.separator {
  max-width: 100%;
  height: 1px;
  border-bottom: solid 1px #979797;
  margin: 0 20px;

  @include media-breakpoint-up(lg) {
    margin: 0 42px;
  }
}
