body {
  overflow-x: hidden;
}

.pushable {
  padding-top: $navbar-small-height;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    padding-top: $navbar-height;
  }

  &.sub-navbar-active {
    padding-top: $navbar-small-height + $sub-navbar-small-height;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      padding-top: $navbar-height + $sub-navbar-height;
    }
  }

  &.navbar-promo-active {
    padding-top: $navbar-small-computed-height;

    @include media-breakpoint-up($navbar-breakpoint-up) {
      padding-top: $navbar-computed-height;
    }

    &.sub-navbar-active {
      padding-top: $navbar-small-computed-height + $sub-navbar-small-height;

      @include media-breakpoint-up($navbar-breakpoint-up) {
        padding-top: $navbar-computed-height + $sub-navbar-height;
      }
    }
  }
}

.pusher {
  min-height: calc(100vh - #{$navbar-small-height});
  overflow-x: hidden;

  @include media-breakpoint-up($navbar-breakpoint-up) {
    min-height: calc(100vh - #{$navbar-height});
  }

  .navbar-promo-active & {
    min-height: calc(100vh - #{$navbar-small-computed-height});

    @include media-breakpoint-up($navbar-breakpoint-up) {
      min-height: calc(100vh - #{$navbar-computed-height});
    }
  }
}

.main-content {
  display: flex;
  flex-direction: column;
}
