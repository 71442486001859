.ribbon {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 0 solid transparent;
    border-bottom: $ribbon-mobile-height solid transparent;
    border-left: $ribbon-mobile-width solid $ribbon-color;

    @include media-breakpoint-up(md) {
      border-bottom: $ribbon-height solid transparent;
      border-left: $ribbon-width solid $ribbon-color;
    }
  }
}
