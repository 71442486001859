.spinner-border {
  border: $spinner-border-width solid $spinner-border-secondary-color;
  border-right-color: currentColor;
}

.spinner-border-sm {
  border-width: $spinner-border-width-sm;
}

.spinner-border-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
  border-width: $spinner-border-width-lg;
}

.btn .spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -$spinner-height-btn / 2;
  margin-left: -$spinner-width-btn / 2;
  border-width: $spinner-border-width-btn;
  width: $spinner-width-btn;
  height: $spinner-height-btn;
}

.link-arrowed-left,
.link-arrowed-right {
  .spinner-border {
    vertical-align: middle;
  }
}

.content-spinner-overlay {
  display: flex;
  background-color: rgba($gray, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
