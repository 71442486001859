.timer-container {
  display: flex;
}

.timer {
  border: $timer-border-color solid $timer-border-width;
  background-color: $timer-color;
  color: $timer-bg;

  @include media-breakpoint-up($timer-breakpoint-up) {
    display: flex;
  }
}

.timer-label {
  padding: $timer-label-padding-y $timer-label-padding-x;
  font-size: $timer-label-font-size-small;
  font-weight: $timer-label-font-weight;
  line-height: $timer-label-line-height;

  @include media-breakpoint-up($timer-breakpoint-up) {
    flex-shrink: 1;
    align-self: center;
    font-size: $timer-label-font-size;
  }

  > br {
    @include media-breakpoint-down($timer-breakpoint-down) {
      display: none;
    }
  }
}

.timer-value {
  display: flex;
  background-color: $timer-bg;
  padding: $timer-value-padding-y $timer-value-padding-x;
  color: $timer-color;
}

.timer-value-block {
  text-align: center;
}

.timer-value-item {
  min-width: $timer-value-item-min-width;
  font-size: $timer-value-item-font-size;
  font-weight: $timer-value-item-font-weight;
  line-height: $timer-value-item-line-height;
}

.timer-value-item-in-square {
  min-width: $timer-value-item-in-square-min-width;
  font-size: $timer-value-item-in-square-font-size;
  font-weight: $timer-value-item-font-weight;
  line-height: $timer-value-item-line-height;

  @include media-breakpoint-up(lg) {
    min-width: $timer-value-item-in-square-min-width-lg;
    font-size: $timer-value-item-in-square-font-size-lg;
  }
}

.timer-value-label {
  font-size: $timer-value-label-font-size;
  line-height: $timer-value-label-line-height;
}

.timer-value-colon {
  font-size: $timer-value-item-font-size;
  font-weight: $timer-value-item-font-weight;
  line-height: $timer-value-item-line-height;
}
