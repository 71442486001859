.toast-title {
  font-size: $toast-title-font-size;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &:before {
    @extend %nbw-icon;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 1;
    color: $toast-icon-color;
    margin-right: 1.25rem;
    font-weight: bold;

    @include circle-icon-size(
      $circle-icon-width-extra-small,
      $circle-icon-height-extra-small,
      $circle-icon-font-size-extra-small
    );

    .toast-success & {
      content: $nbw-icon-check;
      background-color: $toast-success-icon-bg-color;
    }

    .toast-error & {
      content: $nbw-icon-exclamation-mark;
      background-color: $toast-error-icon-bg-color;
    }
  }
}

button.toast-close-button {
  padding: $toast-close-btn-padding;
  text-shadow: none;
}

.toast-close-button {
  &:hover,
  &:focus {
    opacity: 1;
  }
}

#toast-container {
  pointer-events: none;

  > div {
    opacity: 1;
    margin: $toast-margin;
    padding: $toast-padding-y $toast-padding-x;
    border: $toast-border-width solid $toast-border-color;
    width: $toast-width;
    border-radius: $toast-border-radius;
    box-sizing: border-box;
    box-shadow: $toast-box-shadow;
    background-color: $toast-background-color;
    color: $toast-color;
    pointer-events: auto;

    &:hover {
      box-shadow: $toast-box-shadow;
    }

    > .toast-info,
    > .toast-error,
    > .toast-success,
    > .toast-warning {
      background-image: none !important;
    }
  }

  &.toast-bottom-center > div {
    max-width: $toast-max-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
