.text-slider {
  position: relative;
  overflow: hidden;
}

.text-slider-scene {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:first-child {
    position: relative;
    opacity: 1;
  }
}
